import { useComposeProduct } from 'src/app/_api_adb2c/product/product/hooks/use-componse-product';
import {
    ProductBillOfMaterialEntry,
    ProductModel,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import { ProductBillOfMaterialDataObject } from 'src/app/_api_adb2c/product/product/requests/product-bill-of-material-data-object';
import { ProductUpdateDataObject } from 'src/app/_api_adb2c/product/product/requests/product-update-data-object';
import { ProductVersionCreateDataObject } from 'src/app/_api_adb2c/product/product/requests/product-version-create-data-object';
import { BaseDialog } from 'src/app/components-v2/base-dialog';

interface Props {
    open: boolean;
    product: ProductModel;
    data: ProductBillOfMaterialEntry;
    onClose: () => void;
}

export const DeleteProductMaterial = ({
    open,
    product,
    data,
    onClose,
}: Props) => {
    const { mutateAsync, isLoading } = useComposeProduct();

    const onSubmit = async () => {
        const latestVersion = product.versions?.[product.versions.length - 1];

        if (!latestVersion) return;

        const consolidatedComponents = (
            latestVersion?.billOfMaterials || []
        ).map((bom) => {
            return {
                materialCost: bom.materialCost,
                name: bom.name,
                source: bom.source,
                unitOfMeasurement: bom.unitOfMeasurement,
                consumption: bom.consumption,
                resource: bom.resource,
            } as ProductBillOfMaterialDataObject;
        });

        const costOfMaterials = consolidatedComponents
            .filter((x) => x.name === data.name)
            .reduce((acc, curr) => acc + curr.materialCost, 0);

        const versionRequest: ProductVersionCreateDataObject = {
            version: (product.versions?.length || 0) + 1,
            billOfMaterials: consolidatedComponents.filter(
                (x) => x.name !== data.name
            ),
            costOfMaterials: costOfMaterials,
            specifications: {
                width: latestVersion?.specifications?.width || 0,
                height: latestVersion?.specifications?.height || 0,
                weight: latestVersion?.specifications?.weight || 0,
                depth: latestVersion?.specifications?.depth || 0,
            },
        };

        const request: ProductUpdateDataObject = {
            name: product.name,
            versions: [versionRequest],
        };

        await mutateAsync({
            id: product._id,
            body: request,
        });

        onClose();
    };

    return (
        <BaseDialog
            open={open}
            onClose={onClose}
            onConfirm={onSubmit}
            title='Delete Material'
            isLoading={isLoading}
        >
            <div className='flex flex-col gap-2'>
                <span className='text-sm'>
                    Are you sure you want to remove this material?
                </span>

                <span className='text-xs font-light'>
                    * This action cannot be undone. This will permanently delete
                    the selected material from the bill of materials.
                </span>
            </div>
        </BaseDialog>
    );
};
