import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { TreeNode } from 'src/app/components/Form/Tree';
import { Radio } from 'src/app/components/Inputs/Radio';
import { TextInput } from 'src/app/components/Inputs/TextInput';
import { TreeSelect } from 'src/app/components/Inputs/TreeSelect';
import { Button } from 'src/components/ui/button';
import { Separator } from 'src/components/ui/separator';
import { treeOptions } from './add-substance-v3';

interface Props {
    open: boolean;
    projectedWeight: number;
    append: (value: any) => void;
    onClose: () => void;
}

export function AddManualSubstance({
    open,
    projectedWeight,
    append,
    onClose,
}: Props) {
    const [manualInput, setManualInput] = useState<{
        name: string;
        cas: string;
        percentage?: number;
        projectedWeight?: number;
        isManMade: boolean;
        substance: TreeNode[];
        composition: any[];
        isManual: boolean;
    }>({
        name: '',
        cas: '',
        isManMade: false,
        substance: [],
        composition: [],
        isManual: false,
    });

    const [subCompositionInput, setSubCompositionInput] = useState<{
        name: string;
        cas: string;
        percentage?: number;
        projectedWeight?: number;
        isManMade: boolean;
        substance: TreeNode[];
        isManual: boolean;
    }>({
        name: '',
        cas: '',
        percentage: undefined,
        projectedWeight: undefined,
        isManMade: false,
        substance: [],
        isManual: false,
    });

    const isManualInput = manualInput.substance.some((x) =>
        x.value.includes('manualInput')
    );

    const handleSubstanceChange = (value: TreeNode[], node: TreeNode) => {
        if (node.value.includes('manualInput')) {
            setManualInput((prev) => {
                return {
                    ...prev,
                    substance: value,
                    cas: '',
                    percentage: undefined,
                    projectedWeight: undefined,
                    isManMade: false,
                    isManual: true,
                };
            });
        } else {
            setManualInput((prev) => {
                return {
                    ...prev,
                    substance: value,
                    name: node.label,
                    cas: node.value,
                    isManual: false,
                };
            });
        }
    };

    const handleSubCompositionChange = (value: TreeNode[], node: TreeNode) => {
        if (node.value.includes('manualInput')) {
            setSubCompositionInput((prev) => {
                return { ...prev, substance: value };
            });
        } else {
            setSubCompositionInput((prev) => {
                return { ...prev, substance: value, cas: node.value };
            });
        }
    };

    const reset = () => {
        setManualInput({
            name: '',
            cas: '',
            percentage: 0,
            isManMade: false,
            substance: [],
            composition: [],
            isManual: false,
        });

        setSubCompositionInput({
            name: '',
            cas: '',
            percentage: undefined,
            projectedWeight: undefined,
            isManMade: false,
            substance: [],
            isManual: false,
        });

        onClose();
    };

    const addComposition = () => {
        const substanceName =
            subCompositionInput.substance?.[
                subCompositionInput.substance.length - 1
            ].label;

        const newComposition = { ...subCompositionInput, name: substanceName };

        setManualInput((prev) => {
            return {
                ...prev,
                composition: [...prev.composition, newComposition],
            };
        });

        setSubCompositionInput({
            name: '',
            cas: '',
            percentage: undefined,
            projectedWeight: undefined,
            isManMade: false,
            substance: [],
            isManual: false,
        });
    };

    const onConfirm = () => {
        append(manualInput);
        reset();
    };

    const columns: ColumnDef<any>[] = [
        {
            id: 'cas',
            header: 'CAS Number',
            accessorFn: (row) => row.cas,
        },
        {
            id: 'percentage',
            header: 'Percentage',
            accessorFn: (row) => row.percentage,
        },
        {
            id: 'projectedWeight',
            header: 'Projected Weight',
            accessorFn: (row) => row.projectedWeight,
        },
        {
            id: 'isManMade',
            header: 'Man Made',
            accessorFn: (row) => (row.isManMade ? 'Yes' : 'No'),
        },
    ];

    return (
        <>
            <DialogV2
                open={open}
                onClose={reset}
                title='Add Manual Substance'
                isStepDialog
                size='lg'
                onFinish={onConfirm}
                steps={[
                    {
                        title: 'About Your Substance',
                        description:
                            'Provide information about the manual substance',
                        content: (
                            <div className='grid grid-cols-1 gap-2'>
                                <TreeSelect
                                    options={treeOptions}
                                    value={manualInput.substance}
                                    label='Substance'
                                    onChange={(value, node) => {
                                        handleSubstanceChange(value, node);
                                    }}
                                    className='mb-2'
                                />

                                <Separator className='my-2' />

                                {isManualInput && (
                                    <TextInput
                                        label='Name'
                                        value={manualInput.name}
                                        onValueChange={(value) => {
                                            setManualInput((prev) => {
                                                return { ...prev, name: value };
                                            });
                                        }}
                                    />
                                )}

                                <TextInput
                                    label='Cas Number'
                                    value={manualInput.cas}
                                    onValueChange={(value) => {
                                        setManualInput((prev) => {
                                            return { ...prev, cas: value };
                                        });
                                    }}
                                />

                                <TextInput
                                    label='Percentage'
                                    value={manualInput?.percentage || ''}
                                    onValueChange={(value) => {
                                        setManualInput((prev) => {
                                            const calculatedWeight =
                                                (Number(value) / 100) *
                                                projectedWeight;

                                            return {
                                                ...prev,
                                                percentage: Number(value),
                                                projectedWeight:
                                                    calculatedWeight,
                                            };
                                        });
                                    }}
                                />

                                <TextInput
                                    label='Projected Weight'
                                    value={manualInput.projectedWeight || ''}
                                    type='number'
                                    readOnly
                                    className='cursor-not-allowed'
                                    onValueChange={(value) => {
                                        setManualInput((prev) => {
                                            return {
                                                ...prev,
                                                projectedWeight: Number(value),
                                            };
                                        });
                                    }}
                                />
                            </div>
                        ),
                    },
                    {
                        title: 'Substance Composition',
                        description:
                            'Provide information about the substance composition',

                        pageControls: (
                            <>
                                <Button
                                    size='sm'
                                    variant='outline'
                                    className='min-w-[100px]'
                                    onClick={addComposition}
                                >
                                    Add
                                </Button>
                            </>
                        ),
                        hidden: !isManualInput,
                        content: (
                            <div className='grid grid-cols-1 gap-2'>
                                <TableV2
                                    columns={columns}
                                    data={manualInput.composition}
                                    fixedHeight='175px'
                                />

                                <TreeSelect
                                    options={treeOptions.filter(
                                        (x) => x.label !== 'Manual'
                                    )}
                                    value={subCompositionInput.substance}
                                    label='Substance'
                                    onChange={(value, node) => {
                                        handleSubCompositionChange(value, node);
                                    }}
                                    className='mb-2'
                                />

                                <TextInput
                                    label='Cas Number'
                                    value={subCompositionInput.cas}
                                    onValueChange={(value) => {
                                        setSubCompositionInput((prev) => ({
                                            ...prev,
                                            cas: value,
                                        }));
                                    }}
                                />

                                <TextInput
                                    label='Percentage'
                                    value={subCompositionInput.percentage || ''}
                                    type='number'
                                    onValueChange={(value) => {
                                        setSubCompositionInput((prev) => ({
                                            ...prev,
                                            percentage: Number(value),
                                            projectedWeight:
                                                (Number(value) / 100) *
                                                (manualInput?.projectedWeight ||
                                                    0),
                                        }));
                                    }}
                                />

                                <TextInput
                                    label='Projected Weight'
                                    value={
                                        subCompositionInput.projectedWeight ||
                                        ''
                                    }
                                    onValueChange={() => {}}
                                    type='number'
                                    readOnly
                                    className='cursor-not-allowed'
                                />

                                <Radio
                                    name='isManMade'
                                    label='Is Man Made'
                                    options={[
                                        { label: 'Yes', value: 'true' },
                                        { label: 'No', value: 'false' },
                                    ]}
                                    value={subCompositionInput.isManMade}
                                    id='isManMade'
                                    onValueChange={(e) => {
                                        const isTrue = e === 'true';
                                        setSubCompositionInput((prev) => ({
                                            ...prev,
                                            isManMade: isTrue,
                                        }));
                                    }}
                                />
                            </div>
                        ),
                    },
                ]}
            ></DialogV2>
        </>
    );
}
