import { ColumnDef } from '@tanstack/react-table';
import { CircleHelp, GanttChartSquare, PlusIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    PurchaseOrderStatus,
    PurchaseOrderStatusLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-status.enum';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { useContextStore } from 'src/app/stores/context-store';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { AddPurchase } from './add-purchase';

export const Purchases = () => {
    const navigate = useNavigate();
    const { workspace: w } = useContextStore();

    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId');
    const [isCreate, setIsCreate] = useState(false);

    const { data, isLoading } = usePurchases(
        {
            page: 0,
            limit: 0,
            draft: false,
        },
        delegateId || ''
    );

    const { data: suppliers } = useSuppliers(delegateId || '');

    const memoizedData = useMemo<PurchaseModel[]>(() => {
        return (data?.data || [])
            .filter((x) => x.supplier)
            .map((x) => {
                const supplier = suppliers?.find(
                    (s) => s.seller._id === x.supplier._id
                );

                return {
                    ...x,
                    name: supplier?.seller.name,
                    subRows: [], // Ensure subRows is included
                };
            });
    }, [suppliers, data]);

    const handleRedirectToDetails = useCallback(
        (id: string) => {
            delegateId
                ? navigate(`/purchases/${id}?delegateId=${delegateId}`)
                : navigate(`/purchases/${id}`);
        },
        [navigate, delegateId]
    );

    const getPurchaseOrderItemCategory = useCallback(
        (order: PurchaseModel) => {
            if (order.versions) {
                const version = order.versions.find(
                    (v) => (v.manifest || []).length > 0
                );
                if (version?.manifest) {
                    const manifest = version.manifest[0];
                    if (!manifest) {
                        return '--';
                    } else if (manifest.product) {
                        const purchaseable = manifest.product;
                        if (purchaseable.category) {
                            const categoryBase =
                                w?.features.supplyChainLoaderType === 0
                                    ? purchaseable.category.code
                                          ?.split('::_-')
                                          ?.pop()
                                    : purchaseable.category.code
                                          ?.split('::')
                                          ?.shift();
                            if (categoryBase) {
                                return categoryBase
                                    .split(' ')
                                    .map((i) =>
                                        i
                                            .split('_')
                                            .map(
                                                (j) =>
                                                    j
                                                        .slice(0, 1)
                                                        .toUpperCase() +
                                                    j.slice(1).toLowerCase()
                                            )
                                            .join(' ')
                                    )
                                    .join(' ');
                            } else {
                                return '--';
                            }
                        } else {
                            return '--';
                        }
                    } else {
                        return '--';
                    }
                }
            } else {
                return '--';
            }
        },
        [w?.features.supplyChainLoaderType]
    );

    const getOrderTotal = useCallback(
        (order: PurchaseModel) => {
            const version = order.versions[order.versions.length - 1];

            if (version) {
                const total = version.manifest.reduce((acc, item) => {
                    return acc + item.ppu * item.quantity;
                }, 0);

                if (w?.features?.supplyChainLoaderType === 0) {
                    return total.toFixed(2);
                } else {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });
                    return formatter.format(total);
                }
            }
        },
        [w?.features.supplyChainLoaderType]
    );

    const columns: ColumnDef<PurchaseModel>[] = useMemo(() => {
        return [
            {
                id: 'select',
                size: 50,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllPageRowsSelected()}
                        onCheckedChange={(value) =>
                            table.toggleAllPageRowsSelected(!!value)
                        }
                        aria-label='Select all'
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={(value) => row.toggleSelected(!!value)}
                        aria-label='Select row'
                    />
                ),
                enableSorting: false,
                enableHiding: false,
                enableColumnFilter: false,
                filterFn: (row, columnId, filterValue) => {
                    return filterValue.includes(row.getValue(columnId));
                },
            },
            {
                id: 'po number',
                header: 'PO Number',
                accessorFn: (row) => {
                    return generateOrderTitle(row);
                },
                cell: ({ row }) => {
                    return (
                        <span
                            onClick={() => {
                                handleRedirectToDetails(row.original._id || '');
                            }}
                            className='cursor-pointer text-xs text-blue-800 hover:underline'
                        >
                            {generateOrderTitle(row.original)}
                        </span>
                    );
                },
                enableSorting: true,
            },
            {
                id: 'case',
                header: 'Case',
                accessorFn: (row) =>
                    row.reference.find((r) => r.source === 'externalDataId')
                        ?.value,
                enableSorting: true,
            },
            {
                id: 'supplier',
                header: 'Supplier',
                accessorFn: (row) => {
                    return row.supplier.seller.name;
                },
                enableSorting: true,
            },
            {
                id: 'primary Category',
                header: 'Primary Category',
                accessorFn: (row) => {
                    const category = getPurchaseOrderItemCategory(row);

                    return category;
                },
                enableSorting: true,
            },
            {
                id: 'item Number',
                header: 'Item Number',
                accessorFn: (row) => {
                    const order = row;
                    const version = order.versions[order.versions.length - 1];

                    if (!version) return '--';
                    if (version.manifest.length < 1) return '--';

                    const primaryItem = version?.manifest[0];

                    return primaryItem?.product?.name;
                },
                cell: ({ row }) => {
                    const order = row.original;
                    const version = order.versions[order.versions.length - 1];

                    if (!version) return '--';
                    if (version.manifest.length < 1) return '--';

                    const itemCount = version.manifest.length;
                    const primaryItem = version?.manifest[0];

                    const customerItemNo =
                        primaryItem?.product?.externalReferences?.find(
                            (x) => x.source === 'customer'
                        )?.value;
                    const lfItemNo =
                        primaryItem?.product?.externalReferences?.find(
                            (x) => x.source === 'externalDataId'
                        )?.value;
                    const lfItemNoOld = Reflect.get(
                        primaryItem?.product?.externalReferences?.find(
                            (x) => x.source === 'externalDataId'
                        ) ?? {},
                        'reference'
                    );

                    return (
                        <div className='flex items-center gap-2'>
                            {customerItemNo ||
                                lfItemNo ||
                                lfItemNoOld ||
                                primaryItem?.product?.name}
                            <div className='has-tooltip'>
                                <span className='tooltip -mt-8 flex flex-col rounded bg-white px-4 py-2 shadow'>
                                    {version.manifest.map((item) => {
                                        const customerItem =
                                            item?.product?.externalReferences?.find(
                                                (x) => x.source === 'customer'
                                            )?.value;
                                        const lfItem =
                                            item?.product?.externalReferences?.find(
                                                (x) =>
                                                    x.source ===
                                                    'externalDataId'
                                            )?.value;
                                        const lfItemOld = Reflect.get(
                                            item?.product?.externalReferences?.find(
                                                (x) =>
                                                    x.source ===
                                                    'externalDataId'
                                            ) ?? {},
                                            'reference'
                                        );

                                        return (
                                            <div
                                                className='list-item'
                                                key={item?.product?._id}
                                            >
                                                {customerItem ||
                                                    lfItem ||
                                                    lfItemOld ||
                                                    item?.product?.name}
                                            </div>
                                        );
                                    })}
                                </span>
                                {itemCount > 1 && (
                                    <span>
                                        <CircleHelp size={16} />
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                },
                enableSorting: true,
            },
            {
                id: 'currency',
                header: 'Currency',
                accessorFn: (row) => row.currency,
                enableSorting: true,
            },
            {
                id: 'buy Date',
                header: 'Buy Date',
                accessorFn: (row) => {
                    return DateTime.fromJSDate(
                        new Date(row.createdOn)
                    ).toFormat('yyyy-MM-dd');
                },
                enableSorting: true,
            },
            {
                id: 'total',
                header: 'Total',
                accessorFn: (row) => getOrderTotal(row),
                enableSorting: true,
            },
            {
                id: 'quantity',
                header: 'Quantity',
                accessorFn: (row) => {
                    const version = row.versions[row.versions.length - 1];

                    if (version) {
                        return version.manifest.reduce((acc, item) => {
                            return acc + item.quantity;
                        }, 0);
                    }
                },
                enableSorting: true,
            },
            {
                id: 'status',
                header: 'Status',
                accessorFn: (row) =>
                    PurchaseOrderStatusLabels[
                        row.status as PurchaseOrderStatus
                    ],
                enableSorting: true,
            },
            {
                id: 'updatedOn',
                header: 'Updated On',
                accessorFn: (row) => {
                    if (!row.lastUpdatedOn) return '--';

                    return DateTime.fromJSDate(
                        new Date(row.lastUpdatedOn)
                    ).toFormat('yyyy-MM-dd');
                },
                enableSorting: true,
            },
            {
                id: 'actions',
                header: 'Actions',
                disableSorting: true,
                enableColumnFilter: false,
                cell: ({ row }) => {
                    return (
                        <Button
                            variant='ghost'
                            data-testid='details-button'
                            onClick={() =>
                                handleRedirectToDetails(row.original._id || '')
                            }
                        >
                            <GanttChartSquare className='h-4 w-4' />
                        </Button>
                    );
                },
            },
        ];
    }, [getOrderTotal, handleRedirectToDetails, getPurchaseOrderItemCategory]);

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Purchase Orders'
                columns={columns || []}
                data={memoizedData || []}
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                isLoading={isLoading}
                defaultSortField='updatedOn'
                actions={[
                    {
                        id: 'add',
                        icon: <PlusIcon size={16} />,
                        label: 'Add Purchase Order',
                        onClick: () => setIsCreate(true),
                    },
                ]}
            />

            {isCreate && (
                <AddPurchase
                    open={isCreate}
                    onClose={() => setIsCreate(false)}
                />
            )}
        </div>
    );
};
