import { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import { usePurchaseItems } from 'src/app/_api_adb2c/purchase/purchase-item/hooks/use-purchase-items';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from 'src/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from 'src/components/ui/chart';
import { getDateRangeDescription } from '../utils/get-date-range-description';

const chartConfig = {
    count: {
        label: 'Count',
        color: 'hsl(var(--chart-1))',
    },
} satisfies ChartConfig;

interface Props {
    startDate?: Date;
    endDate?: Date;
}

export function ProductCategoryDistribution({ startDate, endDate }: Props) {
    const { data: items } = usePurchaseItems();

    const graphData = useMemo(() => {
        const categoryCounts: { label: string; count: number }[] = [];

        items?.data?.forEach((item) => {
            const createdOn = new Date(item.createdOn);

            if (startDate && endDate) {
                if (createdOn < startDate || createdOn > endDate) return;
            }

            const category = item.category.code;

            const existing = categoryCounts.find((x) => x.label === category);

            if (existing) {
                existing.count++;
            } else {
                categoryCounts.push({
                    label: category,
                    count: 1,
                });
            }
        });

        return categoryCounts;
    }, [items?.data, startDate, endDate]);

    const dateRangeDescription = useMemo(
        () => getDateRangeDescription(startDate, endDate),
        [startDate, endDate]
    );

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle>Product Category Distribution</CardTitle>
                    <CardDescription>{dateRangeDescription}</CardDescription>
                </CardHeader>

                <CardContent>
                    <ChartContainer config={chartConfig}>
                        <BarChart
                            accessibilityLayer
                            data={graphData}
                            layout='vertical'
                            margin={{
                                left: 30,
                            }}
                        >
                            <CartesianGrid />
                            <XAxis
                                type='number'
                                dataKey='count'
                                axisLine={false}
                                tickLine={false}
                            />
                            <YAxis
                                dataKey='label'
                                type='category'
                                tickLine={false}
                                tickMargin={1}
                                axisLine={false}
                                tickFormatter={(value) => value.slice(0, 8)}
                            />
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent />}
                            />
                            <Bar
                                dataKey='count'
                                fill='var(--color-count)'
                                radius={1}
                                barSize={20}
                            />
                        </BarChart>
                    </ChartContainer>
                </CardContent>
            </Card>
        </>
    );
}
