import { ColumnDef } from '@tanstack/react-table';
import { DownloadCloud } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { WorkspaceModel } from 'src/app/_api_adb2c/product/category/category.model';
import { MaterialComposition } from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import { FileModel } from 'src/app/_api_adb2c/workspace/file/file.model';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { Checkbox } from 'src/components/ui/checkbox';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';
import {
    RenderCircle,
    useDownloadBundle,
    VirtualComposition,
} from '../../../Materials/Details/Substance';

interface Props {
    productId: string;
}

export interface VirtualWorkspaceComposition
    extends Omit<MaterialComposition, 'supplier'> {
    supplier?: WorkspaceModel;
    document?: FileModel;
    netWeight?: number;
}

export interface ConsolidatedComposition
    extends Omit<VirtualComposition, 'supplier' | 'sdsFileId'> {
    supplier?: WorkspaceModel[];
    sdsFileId?: any[];
}

export function ProductSubstances({ productId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [items, setItems] = useState<ConsolidatedComposition[]>([]);

    const { data: product } = useProduct(productId, delegateId);

    const { downloadBundle } = useDownloadBundle();

    const download = useCallback(
        async (item: ConsolidatedComposition) => {
            const fileIds = item.sdsFileId;

            if (fileIds?.length === 0) {
                toast.error('No SDS file found, no action taken');
                return;
            }

            const name = `Sds File - ${item.name}`;

            const request = fileIds?.map((id) => ({
                _id: id,
                name,
            }));

            await downloadBundle(request || []);
        },
        [downloadBundle]
    );

    useEffect(() => {
        const latest = product?.versions?.[product.versions.length - 1];
        if (!latest) return;

        const consolidatedCompositions: Record<
            string,
            ConsolidatedComposition
        > = {};

        latest.billOfMaterials?.forEach((bill) => {
            bill?.material?.compositions?.forEach((composition) => {
                const name = composition.substanceCode || composition.name;
                const supplier =
                    composition.supplier as unknown as WorkspaceModel;

                if (!consolidatedCompositions[name]) {
                    consolidatedCompositions[name] = {
                        ...composition,
                        name: composition.name,
                        weight: composition.weight || 0,
                        actualWeight: composition.actualWeight || 0,
                        supplier: supplier ? [supplier] : undefined,
                        sdsFileId: composition.sdsFileId
                            ? [composition.sdsFileId]
                            : [],
                    };

                    return;
                }

                consolidatedCompositions[name].weight += composition?.weight;
                consolidatedCompositions[name].actualWeight +=
                    composition?.actualWeight;

                if (composition.sdsFileId) {
                    consolidatedCompositions[name].sdsFileId?.push(
                        composition.sdsFileId
                    );
                }
            });
        });

        setItems(Object.values(consolidatedCompositions));
    }, [product]);

    const totalWeight = useMemo(() => {
        if (!product) return 0;
        if (!product.versions?.length) return 0;

        const sortedVersions = product.versions.sort(
            (a, b) => a.version - b.version
        );
        const latestVersion = sortedVersions[sortedVersions.length - 1];
        if (!latestVersion) return 0;

        return latestVersion?.billOfMaterials?.reduce(
            (acc, curr) => acc + (curr.weight || 0) / (curr.consumption || 1),
            0
        );
    }, [product]);

    const consolidatedItemColumns: ColumnDef<ConsolidatedComposition>[] =
        useMemo(() => {
            const columns: ColumnDef<ConsolidatedComposition>[] = [
                {
                    id: 'select',
                    size: 100,
                    header: ({ table }) => (
                        <Checkbox
                            checked={table.getIsAllPageRowsSelected()}
                            onCheckedChange={(value) =>
                                table.toggleAllPageRowsSelected(!!value)
                            }
                            aria-label='Select all'
                            data-testid='select-all-button'
                        />
                    ),
                    cell: ({ row }) => {
                        if (row.depth > 0) {
                            return <></>;
                        }

                        return (
                            <div className='flex items-center gap-4'>
                                <Checkbox
                                    checked={row.getIsSelected()}
                                    onCheckedChange={(value) =>
                                        row.toggleSelected(!!value)
                                    }
                                    aria-label='Select row'
                                    data-testid='select-row-button'
                                />
                            </div>
                        );
                    },
                    enableSorting: false,
                    enableHiding: false,
                },
                {
                    id: 'substance',
                    header: 'Substance',
                    accessorFn: (row) => row.substanceName || row.name,
                    cell: ({ row }) => {
                        return (
                            <div className='flex items-center gap-2 capitalize'>
                                <span>
                                    {row.original.substanceCode ||
                                        row.original.substanceName}
                                </span>
                            </div>
                        );
                    },
                },
                {
                    id: 'description',
                    header: 'Description',
                    accessorFn: (row) => {
                        return row.substanceName || row.name;
                    },
                    cell: ({ row }) => {
                        return (
                            <div className='flex items-center gap-2 capitalize'>
                                <span>
                                    {row.original.substanceName ||
                                        row.original.name}
                                </span>
                            </div>
                        );
                    },
                },
                {
                    id: 'weigth',
                    header: 'Weight',
                    accessorFn: (row) => row.weight.toFixed(5),
                },
                {
                    id: 'ratio',
                    header: 'Ratio',
                    cell: ({ row }) => {
                        const ratio =
                            (row.original.weight / (totalWeight || 0)) * 100;

                        return ratio.toFixed(2);
                    },
                },
                {
                    id: 'supplier',
                    header: 'Supplier',
                    accessorFn: (row) => row.supplier,
                    cell: ({ row }) => {
                        const distinctSuppliers = row.original.supplier?.filter(
                            (supplier, index, self) =>
                                index ===
                                self.findIndex(
                                    (item) => item._id === supplier._id
                                )
                        );

                        const distinctFirstSupplier = distinctSuppliers?.[0];
                        const distinctSuppliersLength =
                            distinctSuppliers?.length || 0;

                        return (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger>
                                        {distinctFirstSupplier?.name}{' '}
                                        {distinctSuppliersLength > 1 && (
                                            <span className='text-xs'>
                                                +{distinctSuppliersLength - 1}
                                            </span>
                                        )}
                                    </TooltipTrigger>

                                    <TooltipContent>
                                        <div className='flex flex-col gap-2'>
                                            {distinctSuppliers?.map(
                                                (supplier) => (
                                                    <div className='flex items-center gap-2'>
                                                        <span>
                                                            {supplier.name}
                                                        </span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        );
                    },
                },
                {
                    id: 'manMadeSubstance',
                    header: 'MMS',
                    cell: ({ row }) => {
                        if (row.depth > 0) {
                            return <></>;
                        }

                        return row.original.isManMade ? (
                            <RenderCircle color='completed' />
                        ) : (
                            <RenderCircle color='empty' />
                        );
                    },
                },
                {
                    id: 'reachCode',
                    header: 'REG',
                    accessorFn: (row) => row.substanceRegCode,
                    cell: ({ row }) => {
                        if (row.depth > 0) {
                            return <></>;
                        }

                        return row.original.substanceRegCode ? (
                            <RenderCircle color='completed' />
                        ) : (
                            <RenderCircle color='empty' />
                        );
                    },
                },
                {
                    id: 'SDS',
                    header: 'SDS',
                    accessorFn: (row) => row.sdsFileId,
                    cell: ({ row }) => {
                        if (row.depth > 0) {
                            return <></>;
                        }

                        return row.original.sdsFileId &&
                            row.original.sdsFileId.length > 0 ? (
                            <RenderCircle color='completed' />
                        ) : (
                            <RenderCircle color='empty' />
                        );
                    },
                },
                {
                    id: 'actions',
                    header: 'Actions',
                    cell: ({ row }) => {
                        if (row.depth > 0) {
                            return <></>;
                        }

                        return (
                            <div className='flex gap-4'>
                                <DownloadCloud
                                    size={16}
                                    className={cn(
                                        !row.original.sdsFileId
                                            ? 'cursor-not-allowed'
                                            : '',
                                        'hover:scale-125'
                                    )}
                                    onClick={() => {
                                        if (row.original.sdsFileId) {
                                            download(row.original);
                                        }
                                    }}
                                />
                            </div>
                        );
                    },
                },
            ];

            return columns;
        }, [download, totalWeight]);

    return (
        <>
            <div className='flex h-full flex-col'>
                <TableV2
                    columns={consolidatedItemColumns}
                    data={items}
                    label='Bill of Substance'
                    subRowsField='subCompositions'
                />
            </div>
        </>
    );
}
