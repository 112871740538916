import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { VirtualTable } from 'src/app/components-v2/virtual-table';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import { PurchaseCompanyDocuments } from './purchase-company-documents';
import { PurchaseDocumentItem } from './purchase-documents';
import { PurchaseSupportDocuments } from './purchase-support-documents';
import { PurchaseProductDocuments } from './purchase-product-documents';

interface Props {
    items: PurchaseDocumentItem[];
}

const checkIsPurchaseTrace = (
    purchase: PurchaseModel | PurchaseOrderTraceModel
): purchase is PurchaseOrderTraceModel => {
    return (purchase as PurchaseOrderTraceModel).depth !== undefined;
};

export function PurchaseDocumentTable({ items }: Props) {
    const columns: ColumnDef<PurchaseDocumentItem>[] = useMemo(() => {
        const columns: ColumnDef<PurchaseDocumentItem>[] = [
            {
                id: 'purchaseId',
                header: 'Purchase Order',
                accessorFn: (row) => generateOrderTitle(row.purchase),
            },
            {
                id: 'buyer',
                header: 'Buyer',
                accessorFn: (row) => {
                    return row.workspace?.company.demographics.name;
                },
            },
            {
                id: 'supplier',
                header: 'Supplier',
                accessorFn: (row) => {
                    return checkIsPurchaseTrace(row.purchase)
                        ? row.purchase.supplier.name
                        : row.purchase.supplier.seller.name;
                },
            },
            {
                id: 'company',
                header: 'Company',
                cell: ({ row }) => {
                    return (
                        <PurchaseCompanyDocuments
                            workspace={row.original.workspace}
                        />
                    );
                },
            },
            {
                id: 'product',
                header: 'Product',
                cell: ({ row }) => {
                    return <PurchaseProductDocuments item={row.original} />;
                },
            },
        ];

        const requiredTypes: string[] = [];

        items.forEach((x) => {
            x.requiredDocuments?.forEach((doc) => {
                requiredTypes.push(doc);
            });
        });

        Array.from(new Set(requiredTypes)).forEach((doc) => {
            columns.push({
                id: doc,
                header: () => {
                    const item = SupportDocTypeLabel[doc as SupportDocType];

                    return item;
                },
                cell: ({ row }) => {
                    return (
                        <PurchaseSupportDocuments
                            documentType={doc}
                            item={row.original}
                        />
                    );
                },
            });
        });

        return columns;
    }, [items]);

    return (
        <>
            <div className='max-h-'></div>
            <VirtualTable
                columns={columns}
                data={items}
                headerClassName='bg-red-800 text-white whitespace-nowrap'
                className='table-fixed'
                parentClassName='max-h-fit h-auto'
            />
        </>
    );
}
