import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from '../_api_adb2c/purchase/purchase/models/purchase.model';
import { SalesOrderModel } from '../_api_adb2c/sales/sales/models/sales-order.model';

export const generateOrderTitle = (
    order: PurchaseModel | PurchaseOrderTraceModel | SalesOrderModel
) => {
    const externalDataId = order.reference?.find(
        (x) => x.source === 'externalDataId'
    )?.value;

    const customerPoNumber = order.reference?.find(
        (x) => x.source === 'customer'
    )?.value;

    return customerPoNumber ?? externalDataId ?? `Internal Order - ${order._id.slice(0, 6)}`;
};
