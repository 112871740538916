import { ColumnDef } from '@tanstack/react-table';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { ArrowRight, CloudDownload } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { toast } from 'sonner';
import { useAttachmentDownload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-download';
import { WorkspaceModel } from 'src/app/_api_adb2c/product/category/category.model';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { ManifestItemModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import { useFileService } from 'src/app/_api_adb2c/workspace/file/hooks/use-file-service';
import { VirtualTable } from 'src/app/components-v2/virtual-table';
import {
    RenderCircle,
    VirtualComposition,
} from 'src/app/pages-v2/Materials/Details/Substance';
import { useContextStore } from 'src/app/stores/context-store';
import { Checkbox } from 'src/components/ui/checkbox';
import countries from 'src/infrastructure/config/data/countries.json';

interface Props {
    purchaseId: string;
}

interface SubstanceComposition extends Omit<VirtualComposition, 'supplier'> {
    totalWeight?: number;
    supplier?: WorkspaceModel;
    subRows?: SubstanceComposition[];
    isCustom?: boolean;
    manifest?: ManifestItemModel;
}

export function useDownloadWithFile() {
    const context = useContextStore();
    const { service } = useFileService();
    const { mutateAsync } = useAttachmentDownload();

    const download = async (id: string, autoDownload = true) => {
        if (!id) {
            toast.error('No file to download');
            return;
        }

        const response = await service.batchGet(context.workspace?.id || '', [
            id,
        ]);

        if (response.length === 0) return;

        const file = response?.[0];
        if (file) {
            const token = await mutateAsync({
                body: { id },
            });

            const blob = await axios.get(token.token, {
                responseType: 'blob',
            });

            saveAs(blob.data, file.name);
        }

        return response;
    };

    return { download };
}

export function PurchaseSubstanceV2({ purchaseId }: Props) {
    const { data: purchase } = usePurchase(purchaseId);
    const { download } = useDownloadWithFile();

    const [substanceItems, setSubstanceItems] = useState<
        SubstanceComposition[]
    >([]);

    const totalWeight = useMemo(() => {
        if (substanceItems.length === 0) return 0;

        const weight = substanceItems.reduce((acc, curr) => {
            const subRowsWeight = curr.subRows?.reduce((acc, curr) => {
                return acc + curr.weight;
            }, 0);

            return acc + curr.weight + (subRowsWeight || 0);
        }, 0);

        return weight;
    }, [substanceItems]);

    const columns: ColumnDef<SubstanceComposition>[] = useMemo(() => {
        return [
            {
                id: 'select',
                size: 100,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllPageRowsSelected()}
                        onCheckedChange={(value) =>
                            table.toggleAllPageRowsSelected(!!value)
                        }
                        aria-label='Select all'
                        data-testid='select-all-button'
                    />
                ),
                cell: ({ row }) => {
                    console.log('row', row);
                    if (row.depth > 0) {
                        return <></>;
                    }

                    return (
                        <div className='flex items-center gap-4 px-2'>
                            <Checkbox
                                checked={row.getIsSelected()}
                                onCheckedChange={(value) =>
                                    row.toggleSelected(!!value)
                                }
                                aria-label='Select row'
                                data-testid='select-row-button'
                            />

                            {row.subRows.length > 0 && (
                                <div
                                    className='flex items-center gap-2 '
                                    onClick={() => row.toggleExpanded()}
                                >
                                    <ArrowRight
                                        size={16}
                                        className={
                                            row.getIsExpanded()
                                                ? 'rotate-90 transform'
                                                : ''
                                        }
                                    />
                                    {row.original.name}
                                </div>
                            )}

                            {row.subRows.length === 0 && (
                                <div className='flex items-center gap-2'>
                                    {row.original.name}
                                </div>
                            )}
                        </div>
                    );
                },
                enableSorting: false,
                enableHiding: false,
            },
            {
                id: 'substance',
                header: 'Substance',
                cell: ({ row }) => {
                    console.log(row.original);
                    return (
                        <div className='flex items-center gap-2 capitalize'>
                            <span>
                                {row.original.substanceCode ||
                                    row.original.substanceName}
                            </span>
                        </div>
                    );
                },
            },
            {
                id: 'description',
                header: 'Description',
                cell: ({ row }) => {
                    return (
                        <div className='flex items-center gap-2 capitalize'>
                            <span>{row.original.substanceCode || '--'}</span>
                        </div>
                    );
                },
            },
            {
                id: 'weight',
                header: 'Weight',
                accessorFn: (row) => row.weight.toFixed(5),
            },
            {
                id: 'ratio',
                header: 'Ratio',
                cell: ({ row }) => {
                    const ratio =
                        (row.original.weight / (totalWeight || 1)) * 100;

                    return ratio.toFixed(2);
                },
            },
            {
                id: 'supplier',
                header: 'Supplier',
                accessorFn: (row) => row.supplier?.name,
            },
            {
                id: 'COO',
                header: 'COO',
                cell: ({ row }) => {
                    const country = countries.find(
                        (country) =>
                            country['alpha-2'] ===
                            row.original.supplier?.country
                    );

                    return (
                        <ReactCountryFlag
                            countryCode={row.original.supplier?.country || ''}
                            className='text-xl'
                            aria-label={row.original.supplier?.country}
                            title={country?.name}
                        />
                    );
                },
            },
            {
                id: 'manMadeSubstance',
                header: 'MMS',
                cell: ({ row }) => {
                    return row.original.isManMade ? (
                        <RenderCircle color='completed' />
                    ) : (
                        <RenderCircle color='empty' />
                    );
                },
            },
            {
                id: 'reachCode',
                header: 'REG',
                accessorFn: (row) => row.substanceRegCode,
                cell: ({ row }) => {
                    return row.original.substanceRegCode ? (
                        <RenderCircle color='completed' />
                    ) : (
                        <RenderCircle color='empty' />
                    );
                },
            },
            {
                id: 'SDS',
                header: 'SDS',
                accessorFn: (row) => row.sdsFileId,
                cell: ({ row }) => {
                    return row.original.sdsFileId ? (
                        <RenderCircle color='completed' />
                    ) : (
                        <RenderCircle color='empty' />
                    );
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <div className='flex gap-4'>
                            <CloudDownload
                                size={16}
                                className='hover:scale-125'
                                onClick={() => {
                                    download(row.original.sdsFileId || '');
                                }}
                            />
                        </div>
                    );
                },
            },
        ];
    }, [totalWeight, download]);

    useEffect(() => {
        if (purchase) {
            const substanceItems: SubstanceComposition[] = [];

            purchase.versions.forEach((version) => {
                version.manifest.forEach((manifest) => {
                    const versions = manifest.product.versions;
                    const sortedVersions = versions?.sort(
                        (a, b) => a.version - b.version
                    );

                    const manifestVersion = manifest.version
                        ? manifest.version - 1
                        : sortedVersions?.[sortedVersions.length - 1].version;
                    const latestProduct = manifest.product.versions?.find(
                        (version) => version.version === manifestVersion
                    );

                    if (!latestProduct) return;

                    const item: SubstanceComposition = {
                        name: manifest.product.name,
                        subRows: [],
                        isCustom: true,
                        weight: 0,
                        actualWeight: 0,
                    };

                    latestProduct.billOfMaterials?.forEach((bill) => {
                        bill?.material?.compositions?.forEach((composition) => {
                            item.subRows?.push({
                                ...composition,
                                weight:
                                    composition.weight * manifest.quantity || 0,
                                supplier:
                                    composition.supplier as unknown as WorkspaceModel,
                            });
                        });
                    });

                    substanceItems.push(item);
                });
            });

            setSubstanceItems(substanceItems);
        }
    }, [purchase]);

    console.log('substance items', substanceItems);

    return (
        <div className='flex h-full flex-col'>
            <div className='flex h-10 w-full items-center justify-between rounded-t-lg border bg-red-800 px-4 py-2'>
                <span className='text-sm font-bold text-white'>
                    Bill of Substance
                </span>

                <div className='flex gap-4'></div>
            </div>

            <VirtualTable
                columns={columns}
                data={substanceItems}
                parentClassName='flex-1 max-h-full'
                subRowsField='subRows'
                toggleAllRowsExpanded
            />
        </div>
    );
}
