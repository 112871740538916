import { ExternalLink } from 'lucide-react';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { Handle, Position } from 'reactflow';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import SupplierShippedImage from 'src/assets/cascade-shipped.png';
import SupplierCascadeImage from 'src/assets/cascade-supplier.png';
import SupplierCottonImage from 'src/assets/cotton-image.png';
import SupplierFactoryImage from 'src/assets/factory.png';
import { Card, CardContent } from 'src/components/ui/card';
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from 'src/components/ui/sheet';
import { PurchaseManifest } from '../../Details/purchase-manifest';
import { PurchaseCascadeNodeOverview } from '../../purchase-cascade-node-overview';
import { cn } from 'src/lib/utils';

export interface NodeProps {
    data: NodeDataProps;
}

interface NodeDataProps extends React.HTMLAttributes<HTMLDivElement> {
    purchase: PurchaseModel | PurchaseOrderTraceModel;
    supplierMode: boolean;
    supplierName: string;
    upperTierWeight: number;
}

const checkIsPurchaseTrace = (
    purchase: PurchaseModel | PurchaseOrderTraceModel
): purchase is PurchaseOrderTraceModel => {
    return (purchase as PurchaseOrderTraceModel)?.depth !== undefined;
};

export function PurchaseCascadeNode({ data }: NodeProps) {
    const { purchase, supplierMode, supplierName, upperTierWeight } = data;
    const [open, setOpen] = useState(false);

    const orderInformation = useMemo(() => {
        const version = purchase?.versions?.[purchase.versions.length - 1];

        if (!version)
            return {
                cost: 0,
                count: 0,
            };

        const cost = version.manifest.reduce((acc, item) => {
            return acc + item.ppu * item.quantity;
        }, 0);

        const count = version.manifest.reduce((acc, item) => {
            return acc + item.quantity;
        }, 0);

        return {
            cost: cost,
            count: count,
        };
    }, [purchase]);

    const memoizedCottonInformation = useMemo(() => {
        if (!purchase)
            return {
                isApparel: false,
                cottonWeight: 0,
            };

        const version = purchase.versions[purchase.versions.length - 1];
        if (!version)
            return {
                isApparel: false,
                cottonWeight: 0,
            };

        const isApparel = version.manifest.some((x) => x.product?.isApparel);

        if (!isApparel) return { isApparel, cottonWeight: 0 };

        return {
            isApparel,
            cottonWeight: purchase.cottonWeight || 0,
        };
    }, [purchase]);

    const shippedDate = useMemo(() => {
        const shippedOn = purchase?.shippedOn?.[0];

        if (!shippedOn) return '--';

        return DateTime.fromJSDate(new Date(shippedOn)).toFormat('yyyy-MM-dd');
    }, [purchase]);

    return (
        <div className='h-28 w-44 cursor-default rounded-md border-2 border-gray-400 bg-white'>
            <Handle type='target' position={Position.Left}></Handle>

            <Sheet
                open={open}
                onOpenChange={(open) => {
                    setOpen(open);
                }}
            >
                <SheetTrigger className='flex w-full justify-between gap-4 border-b border-gray-400 bg-gray-200 p-1'>
                    <span className='truncate text-xs font-semibold text-gray-500'>
                        {supplierMode
                            ? supplierName
                            : generateOrderTitle(purchase)}
                    </span>

                    <div className='cursor-pointer'>
                        <ExternalLink className='h-4 w-4 flex-1 text-gray-700' />
                    </div>
                </SheetTrigger>

                <SheetContent className='min-w-[800px]'>
                    <SheetHeader>
                        <SheetTitle>
                            Purchase Order
                            <SheetDescription>
                                {generateOrderTitle(purchase)}
                            </SheetDescription>
                        </SheetTitle>
                    </SheetHeader>

                    <div className='mt-8 flex flex-col gap-8'>
                        <div>
                            <span className='text-sm font-bold'>
                                General Information
                            </span>

                            <Card className='px-2 py-4'>
                                <CardContent className='flex gap-2 overflow-auto'>
                                    <PurchaseCascadeNodeOverview
                                        id={purchase._id}
                                        workspace={
                                            checkIsPurchaseTrace(purchase)
                                                ? purchase.workspace
                                                : purchase.supplier.workspace
                                                      ._id
                                        }
                                    />
                                </CardContent>
                            </Card>
                        </div>

                        <div>
                            <span className='text-sm font-bold'>
                                Manifest Items
                            </span>

                            <PurchaseManifest
                                data={purchase}
                                allowNavigate={false}
                            />
                        </div>
                    </div>
                </SheetContent>
            </Sheet>

            <div className='flex flex-col gap-2 px-2 py-2'>
                {!supplierMode && (
                    <div className='flex gap-2'>
                        <img
                            src={SupplierFactoryImage}
                            alt=''
                            className='h-4 w-4'
                        />

                        <span className='w-4/5 truncate text-xs font-semibold text-gray-500'>
                            {checkIsPurchaseTrace(purchase)
                                ? purchase.supplier.name
                                : purchase.supplier.seller.name}
                        </span>
                    </div>
                )}

                {supplierMode && (
                    <div className='flex gap-2'>
                        <img
                            src={SupplierCascadeImage}
                            alt=''
                            className='h-4 w-4'
                        />

                        <span className='w-4/5 truncate text-xs font-semibold text-gray-500'>
                            {orderInformation.cost} (
                            {orderInformation.count || 0} items)
                        </span>
                    </div>
                )}

                <div className='flex gap-2'>
                    <img
                        src={SupplierShippedImage}
                        alt=''
                        className='h-4 w-4'
                    />

                    <span className='w-4/5 truncate text-xs font-semibold text-gray-500'>
                        {shippedDate}
                    </span>
                </div>

                <div className='flex justify-between'>
                    <div
                        className={cn(
                            'flex gap-2',
                            // upperTierWeight &&
                            memoizedCottonInformation.cottonWeight >
                                upperTierWeight
                                ? 'bg-red-400 text-white'
                                : 'text-green-500'
                        )}
                    >
                        <img
                            src={SupplierCottonImage}
                            alt=''
                            className='h-4 w-4'
                        />
                        {/* <span className='w-4/5 truncate text-xs font-semibold text-gray-500'> */}
                        <span
                            className={cn(
                                'w-4/5 truncate text-xs font-semibold text-green-500'
                            )}
                        >
                            {memoizedCottonInformation.cottonWeight} kg
                        </span>
                    </div>

                    {/* {isDelegated && (
                        <PlusCircle
                            onClick={() => {
                                const isTrace = checkIsPurchaseTrace(purchase);

                                setCascadeInfo({
                                    delegateId: isTrace
                                        ? purchase.supplier._id
                                        : purchase.supplier.seller._id,
                                    purchaseOrderId: purchase._id,
                                    workspaceId: isTrace
                                        ? purchase.workspace
                                        : purchase.supplier.workspace._id,
                                });
                            }}
                            className='h-4 w-4'
                        />
                    )} */}
                </div>
            </div>

            <Handle type='source' position={Position.Right}></Handle>
        </div>
    );
}
