import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useBaseCategories } from 'src/app/_api_adb2c/product/category/hooks/use-base-categories';
import { useCategories } from 'src/app/_api_adb2c/product/category/hooks/use-categories';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { SwitchInput } from 'src/app/components/Form/SwitchInput';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { UnitType, UnitTypeLabel } from 'src/domain/enums/unit-type.enum';

export const AddProductRequiredFields = () => {
    const formContext = useFormContext();
    const delegateId = formContext.watch('delegateId');
    const { data: baseCategories } = useBaseCategories('apparel');
    const { data: categories } = useCategories(delegateId || '');

    const memoizedBaseTypes = useMemo(() => {
        const baseTypes: string[] = [];

        baseCategories?.forEach((x) => {
            const name =
                x.base.name.locales.find((y) => y.localeName === 'en')?.text ||
                '';

            baseTypes.push(name);
        });

        return Array.from(new Set(baseTypes));
    }, [baseCategories]);

    const selectedBase = formContext.watch('base');
    const memoizedCategory = useMemo(() => {
        if (!selectedBase) return [];

        if (selectedBase === 'custom') {
            return (categories || []).map((x) => ({
                label: x.name,
                value: x._id,
            }));
        }

        return (baseCategories || [])
            ?.filter((x) => {
                const name =
                    x.base.name.locales.find((y) => y.localeName === 'en')
                        ?.text || '';

                return name === selectedBase;
            })
            .map((x) => ({
                label:
                    x.name.locales.find((y) => y.localeName === 'en')?.text ||
                    '',
                value: x._id,
            }));
    }, [selectedBase, baseCategories, categories]);

    return (
        <>
            <TextInputV2 name='name' label='Name' />

            <div className='grid grid-cols-2 gap-4'>
                <SelectInputV2
                    label='Category Type'
                    name='base'
                    options={[
                        { label: 'Custom', value: 'custom' },
                        ...memoizedBaseTypes
                            .sort((a, b) => a.localeCompare(b))
                            .map((x) => ({
                                label: x,
                                value: x,
                            })),
                    ]}
                />

                <SelectInputV2
                    label='Sub Category'
                    name='category'
                    options={memoizedCategory.map((x) => ({
                        label: x.label,
                        value: x.value,
                    }))}
                />
            </div>

            <SelectInputV2
                label='Unit'
                name='unit'
                options={Object.values(UnitType).map((unit) => ({
                    label: UnitTypeLabel[unit],
                    value: unit,
                }))}
            />

            <TextInputV2
                name='measureValue'
                label='Measure Value'
                type='number'
            />

            <SwitchInput name='isActive' label='Active' />
        </>
    );
};
