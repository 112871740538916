import {
    ColumnDef,
    OnChangeFn,
    Row,
    RowSelectionState,
    SortDirection,
    SortingState,
    Table,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import React, {
    HTMLAttributes,
    forwardRef,
    useEffect,
    useMemo,
    useState,
    useRef,
} from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { Button } from 'src/components/ui/button';
import { TableCell, TableHead, TableRow } from 'src/components/ui/table';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';
import {
    TableDelegationControls,
    TableSearchControls,
} from '../pages-v2/Purchases/reusable-table';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { toast } from 'sonner';

const TableComponent = forwardRef<
    HTMLTableElement,
    React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
    <table
        ref={ref}
        className={cn('w-full caption-bottom text-sm', className)}
        {...props}
    ></table>
));

TableComponent.displayName = 'TableComponent';

const CellWithTooltip = ({ content }: { content: React.ReactNode }) => (
    <TooltipProvider>
        <Tooltip>
            <TooltipTrigger asChild>
                <div className='max-w-[175px] cursor-pointer truncate'>
                    {content}
                </div>
            </TooltipTrigger>
            <TooltipContent className='shadow-m border bg-white text-black'>
                {content}
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
);

const CellContent = ({
    cell,
    disableTooltip,
}: {
    cell: any;
    disableTooltip: boolean;
}) => {
    const content = flexRender(cell.column.columnDef.cell, cell.getContext());

    return disableTooltip ? (
        <div className='h-8 w-full content-center truncate'>{content}</div>
    ) : (
        <CellWithTooltip content={content} />
    );
};

const TableGroupRowComponent = <TData,>(
    groupedRows: Record<string, TData[]>,
    disableTooltips = false,
    disabledTooltipColumns: string[] = [],
    expanded: any,
    setExpanded: (expanded: any) => void,
    columns: ColumnDef<TData, any>[],
    table: Table<TData>,
    groupLabels?: Record<string, string>,
    subRowsField?: keyof TData
) => {
    const isCustom = (data: TData) =>
        subRowsField ? (data[subRowsField] as TData[])?.length > 0 : false;

    const renderGroupRow = (
        group: string,
        expanded: boolean,
        onClick: () => void
    ) => (
        <TableRow
            className='cursor-pointer bg-gray-100 hover:bg-gray-200'
            onClick={onClick}
        >
            <TableCell
                colSpan={columns.length}
                className='h-10 text-xs font-medium'
            >
                <div className='flex w-full items-center gap-2'>
                    {expanded ? (
                        <ChevronDown size={16} />
                    ) : (
                        <ChevronRight size={16} />
                    )}
                    {groupLabels?.[group] || group}
                </div>
            </TableCell>
        </TableRow>
    );

    return function getTableGroupRow(
        props: HTMLAttributes<HTMLTableRowElement>
    ) {
        return (
            <>
                {Object.entries(groupedRows).map(([group, items]) => (
                    <React.Fragment key={group}>
                        {renderGroupRow(group, expanded[group], () => {
                            const newExpanded = { ...expanded };
                            newExpanded[group] = !expanded[group];
                            setExpanded(newExpanded);
                        })}

                        {expanded[group] &&
                            items.map((item: any, index) => {
                                const row = table
                                    .getRowModel()
                                    .rows.find((row) => row.original === item);

                                const depth = row?.depth || 0;
                                const marginLeft =
                                    depth > 1 ? `${depth * 8}px` : '0px';

                                return (
                                    <TableRow
                                        key={index}
                                        data-state={
                                            row?.getIsSelected() && 'selected'
                                        }
                                        className={cn(
                                            'bg-white hover:bg-muted',
                                            isCustom(item) &&
                                                'cursor-pointer font-medium'
                                        )}
                                        style={{ marginLeft }}
                                        onClick={() => {
                                            if (isCustom(item) && row) {
                                                row.toggleExpanded(
                                                    !row.getIsExpanded()
                                                );
                                            }
                                        }}
                                    >
                                        {row
                                            ?.getVisibleCells()
                                            .map((cell, cellIndex) => {
                                                const shouldDisableTooltip =
                                                    disableTooltips ||
                                                    disabledTooltipColumns.includes(
                                                        cell.column.id
                                                    );

                                                return (
                                                    <TableCell
                                                        key={cell.id}
                                                        className={cn(
                                                            'h-12 px-4 text-xs',
                                                            cell.column
                                                                .columnDef.meta
                                                                ?.columnsClassName,
                                                            cellIndex === 0 &&
                                                                isCustom(
                                                                    item
                                                                ) &&
                                                                'relative pl-8 before:absolute before:left-3 before:text-gray-500 before:transition-transform before:content-["►"]',
                                                            cellIndex === 0 &&
                                                                isCustom(
                                                                    item
                                                                ) &&
                                                                row.getIsExpanded() &&
                                                                'before:rotate-90'
                                                        )}
                                                    >
                                                        <CellContent
                                                            cell={cell}
                                                            disableTooltip={
                                                                shouldDisableTooltip
                                                            }
                                                        />
                                                    </TableCell>
                                                );
                                            })}
                                    </TableRow>
                                );
                            })}
                    </React.Fragment>
                ))}
            </>
        );
    };
};

// Simplify TableRowComponent similarly
const TableRowComponent = <TData,>(
    rows: Row<TData>[],
    disableTooltips = false,
    disabledTooltipColumns: string[] = [],
    subRowsField?: keyof TData
) =>
    function getTableRow(props: HTMLAttributes<HTMLTableRowElement>) {
        // @ts-expect-error data-index is a valid attribute
        const index = props?.['data-index'];
        const row = rows[index];

        if (!row) return null;

        const isCustom = (data: TData) =>
            subRowsField ? (data[subRowsField] as TData[])?.length > 0 : false;

        const depth = row?.depth || 0;
        const marginLeft = depth > 1 ? `${depth * 8}px` : '0px';

        return (
            <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className={cn(
                    'bg-white hover:bg-muted',
                    isCustom(row.original) && 'cursor-pointer font-medium'
                )}
                style={{ marginLeft }}
                onClick={() => {
                    if (isCustom(row.original)) {
                        row.toggleExpanded(!row.getIsExpanded());
                    }
                }}
                {...props}
            >
                {row.getVisibleCells().map((cell, cellIndex) => {
                    const shouldDisableTooltip =
                        disableTooltips ||
                        disabledTooltipColumns.includes(cell.column.id);

                    return (
                        <TableCell
                            key={cell.id}
                            className={cn(
                                'h-12 px-4 text-xs',
                                cell.column.columnDef.meta?.columnsClassName
                            )}
                        >
                            {cellIndex === 0 && (
                                <div className='flex w-full items-center gap-2'>
                                    {Array.from({ length: row.depth }).map(
                                        (_, i) => (
                                            <span key={i} className='w-6' />
                                        )
                                    )}

                                    <CellContent
                                        cell={cell}
                                        disableTooltip={shouldDisableTooltip}
                                    />

                                    {isCustom(row.original) &&
                                        (row.getIsExpanded() ? (
                                            <ChevronDown className='h-4 w-4' />
                                        ) : (
                                            <ChevronRight className='h-4 w-4' />
                                        ))}
                                </div>
                            )}
                            {cellIndex !== 0 && (
                                <CellContent
                                    cell={cell}
                                    disableTooltip={shouldDisableTooltip}
                                />
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    };

function SortingIndicator({ isSorted }: { isSorted: SortDirection | false }) {
    if (!isSorted) return null;
    return (
        <div>
            {
                {
                    asc: '↑',
                    desc: '↓',
                }[isSorted]
            }
        </div>
    );
}

interface DataTableProps<TData, TSubRowField extends keyof TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    maxHeightOffset?: number;
    label?: string;
    actions?: TableAction[];
    controls?: TableControls;
    rowSelection?: RowSelectionState;
    setRowSelection?: OnChangeFn<RowSelectionState>;
    isLoading?: boolean;
    fixedHeight?: string;
    disableTooltips?: boolean;
    disabledTooltipColumns?: string[];
    tableClassName?: string;
    tableContainerClassName?: string;
    tableHeaderClassName?: string;
    subRowsField?: TSubRowField;
    groupBy?: string;
    groupLabels?: Record<string, string>;
    defaultSortField?: string;
}

interface TableAction {
    id: string;
    icon?: React.ReactNode;
    label?: string;
    onClick: (e?: React.MouseEvent) => void;
    condition?: {
        isAllowed: boolean;
        message?: string;
    };
}

interface TableControls {
    enableSearch?: boolean;
    enableDelegation?: boolean;
}

export function TableV2<TData, TSubRowField extends keyof TData, TValue>({
    columns,
    data,
    maxHeightOffset = 200,
    label,
    actions,
    controls,
    rowSelection,
    setRowSelection,
    isLoading = false,
    fixedHeight,
    disableTooltips = false,
    disabledTooltipColumns = [],
    tableClassName,
    tableContainerClassName,
    tableHeaderClassName,
    subRowsField,
    groupBy,
    groupLabels,
    defaultSortField,
}: DataTableProps<TData, TSubRowField, TValue>) {
    const [sorting, setSorting] = useState<SortingState>(
        defaultSortField ? [{ id: defaultSortField, desc: true }] : []
    );
    const [expanded, setExpanded] = useState({});
    const [tableHeight, setTableHeight] = useState('100vh');
    const containerRef = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLElement | null>(null);
    const { enableSearch, enableDelegation } = controls || {};
    const [search, setSearch] = useState('');
    const [globalFilter, setGlobalFilter] = useState<any[]>([]);

    const getSubRows = (row: TData) => {
        if (!subRowsField) return [];

        return row[subRowsField] as TData[];
    };

    useEffect(() => {
        if (!fixedHeight) {
            const updateHeight = () => {
                if (containerRef.current) {
                    parentRef.current = containerRef.current.parentElement;
                    const parentHeight = parentRef.current?.offsetHeight || 0;
                    const containerTop =
                        containerRef.current.getBoundingClientRect().top;
                    const windowHeight = window.innerHeight;

                    const controlsOffset =
                        enableSearch || enableDelegation ? 40 : 0;
                    const labelOffset = label ? 40 : 0;

                    // Check if the table is inside a dialog
                    const isInDialog =
                        containerRef.current.closest('[role="dialog"]') !==
                        null;

                    const dialogOffset = isInDialog ? 40 : 0;

                    const availableHeight = Math.min(
                        parentHeight,
                        windowHeight - containerTop - dialogOffset
                    );

                    const calculatedHeight =
                        availableHeight - 40 - controlsOffset - labelOffset;
                    console.log('calculatedHeight', calculatedHeight);
                    console.log('availableHeight', availableHeight);
                    console.log('windowHeight', windowHeight);
                    console.log('containerTop', containerTop);
                    console.log(containerRef.current.scrollHeight);

                    setTableHeight(`${Math.max(400, calculatedHeight)}px`);
                }
            };

            updateHeight();
            window.addEventListener('resize', updateHeight);
            return () => window.removeEventListener('resize', updateHeight);
        } else {
            setTableHeight(fixedHeight);
        }
    }, [fixedHeight, enableSearch, enableDelegation, label]);

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            globalFilter,
            expanded,
            rowSelection: rowSelection || {},
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        onExpandedChange: setExpanded,
        onRowSelectionChange: setRowSelection || undefined,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (row) => getSubRows(row),
    });

    const { rows } = table.getRowModel();

    const groupedRows = useMemo(() => {
        if (!groupBy || !data.length) return null;

        return data.reduce((groups: Record<string, TData[]>, item: any) => {
            const group = item[groupBy] || 'Others';

            if (!groups[group]) groups[group] = [];
            groups[group].push(item);

            return groups;
        }, {});
    }, [data, groupBy]);

    return (
        <div ref={containerRef} className={tableContainerClassName}>
            {(enableDelegation || enableSearch) && (
                <div
                    id='control-bar'
                    className='mb-4 flex items-center gap-4 px-1'
                >
                    {enableSearch && (
                        <TableSearchControls
                            table={table}
                            search={search}
                            setSearch={setSearch}
                        />
                    )}

                    {enableDelegation && (
                        <TableDelegationControls
                            table={table}
                            search={search}
                            setSearch={setSearch}
                        />
                    )}
                </div>
            )}

            {label && (
                <div className='flex h-10 w-full items-center justify-between rounded-t-lg border bg-red-800 px-4 py-2'>
                    <span className='text-sm font-bold text-white'>
                        {label}
                    </span>

                    <div className='flex gap-4'>
                        {actions?.map((action) => (
                            <TooltipProvider key={action.id}>
                                <Tooltip>
                                    <TooltipTrigger className='bg-red-800'>
                                        <Button
                                            variant='ghost'
                                            className='h-full py-2 text-white hover:bg-red-700 hover:text-white hover:opacity-80 hover:shadow'
                                            size='sm'
                                            type='button'
                                            id={action.id}
                                            data-testid={action.id}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                if (
                                                    action.condition &&
                                                    !action.condition.isAllowed
                                                ) {
                                                    toast.error(
                                                        action.condition
                                                            .message ||
                                                            'This action is not allowed'
                                                    );
                                                    return;
                                                }

                                                action.onClick(e);
                                            }}
                                        >
                                            {action.icon}
                                        </Button>
                                    </TooltipTrigger>

                                    {action.label && (
                                        <TooltipContent className='border bg-white text-black shadow-lg'>
                                            {action.label}
                                        </TooltipContent>
                                    )}
                                </Tooltip>
                            </TooltipProvider>
                        ))}
                    </div>
                </div>
            )}

            <div className='max-h-[inherit] rounded-md border'>
                <div className='relative max-h-[inherit]'>
                    {isLoading && (
                        <div className='absolute inset-0 z-50 flex items-center justify-center bg-white/50'>
                            <div className='h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent' />
                        </div>
                    )}

                    <TableVirtuoso
                        style={{
                            height: tableHeight,
                            maxHeight: 'inherit',
                        }}
                        totalCount={rows.length}
                        overscan={900}
                        components={{
                            Table: (props) => (
                                <TableComponent
                                    {...props}
                                    className={tableClassName}
                                />
                            ),
                            TableRow: groupedRows
                                ? TableGroupRowComponent(
                                      groupedRows,
                                      disableTooltips,
                                      disabledTooltipColumns,
                                      expanded,
                                      setExpanded,
                                      columns,
                                      table,
                                      groupLabels,
                                      subRowsField
                                  )
                                : TableRowComponent(
                                      rows,
                                      disableTooltips,
                                      disabledTooltipColumns,
                                      subRowsField
                                  ),
                        }}
                        className='bg-white'
                        fixedHeaderContent={() =>
                            table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <TableHead
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                style={{
                                                    width: header.getSize(),
                                                }}
                                                className={cn(
                                                    'h-10 whitespace-nowrap px-4 text-xs',
                                                    tableHeaderClassName
                                                )}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div
                                                        className={cn(
                                                            'flex items-center gap-2 text-xs',
                                                            header.column.getCanSort() &&
                                                                'cursor-pointer select-none hover:text-primary hover:underline'
                                                        )}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                    >
                                                        {flexRender(
                                                            header.column
                                                                .columnDef
                                                                .header,
                                                            header.getContext()
                                                        )}
                                                        <SortingIndicator
                                                            isSorted={header.column.getIsSorted()}
                                                        />
                                                    </div>
                                                )}
                                            </TableHead>
                                        );
                                    })}
                                </TableRow>
                            ))
                        }
                    />
                </div>
            </div>
        </div>
    );
}
