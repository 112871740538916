import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';

export class ProductApprovalApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'products', 'product-approval');
    }

    getByWorkspace(workspace: string) {
        console.log(`client<product-approval>| getByWorkspace(): Enter`);
        console.log(
            `client<product-approval>| getByWorkspace(): $workspace = ${workspace}`
        );
        return super.get('getByWorkspace', workspace);
    }

    getByApprover(workspace: string) {
        console.log(`client<product-approval>| getByApprover(): Enter`);
        console.log(
            `client<product-approval>| getByApprover(): $workspace = ${workspace}`
        );
        return super.get('getByApprover', workspace);
    }

    get(workspace: string, item: string) {
        console.log(`client<product-approval>| get(): Enter`);
        console.log(
            `client<product-approval>| get(): $workspace = ${workspace}`
        );
        console.log(`client<product-approval>| get(): $item = ${item}`);

        return super.get('get', workspace, item);
    }

    create(workspace: string, body: any) {
        console.log(`client<product-approval>| create(): Enter`);
        console.log(
            `client<product-approval>| create(): $workspace = ${workspace}`
        );
        console.log(`client<product-approval>| create(): $body = ${body}`);
        return super.post('create', workspace, body);
    }

    approve(workspace: string, item: string) {
        console.log(`client<product-approval>| approve(): Enter`);
        console.log(
            `client<product-approval>| approve(): $workspace = ${workspace}`
        );
        console.log(`client<product-approval>| approve(): $item = ${item}`);
        return super.patch('approve', workspace, item, {});
    }

    reject(workspace: string, item: string, body: any) {
        console.log(`client<product-approval>| reject(): Enter`);
        console.log(
            `client<product-approval>| reject(): $workspace = ${workspace}`
        );
        console.log(`client<product-approval>| reject(): $item = ${item}`);
        console.log(`client<product-approval>| reject(): $body = ${body}`);
        return super.patch('reject', workspace, item, body);
    }

    remove(workspace: string, item: string) {
        console.log(`client<product-approval>| remove(): Enter`);
        console.log(
            `client<product-approval>| remove(): $workspace = ${workspace}`
        );
        console.log(`client<product-approval>| remove(): $item = ${item}`);
        return super.delete('remove', workspace, item);
    }
}
