import { ColumnDef } from '@tanstack/react-table';
import { GanttChartSquare } from 'lucide-react';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { ReusableTable } from '../../Purchases/reusable-table';

interface SubstanceItem {
    substance: string;
    cas: string;
    weight: number;
    category: string[];
    lastUpdatedOn: Date;
    name: string;
    shippedOn: Date | null;
}

export function SubstanceReportTable() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const startDate = searchParams.get('startDate') || '';
    const endDate = searchParams.get('endDate') || '';
    const thresholdWeight = parseFloat(
        searchParams.get('thresholdWeight') || '0'
    );
    const category = searchParams.get('category') || '';
    const substanceName = searchParams.get('substanceName') || '';
    const casNumber = searchParams.get('casNumber') || '';

    const { data: orders, isLoading } = usePurchases({
        page: 0,
        limit: 0,
        draft: false,
    });

    const columns: ColumnDef<SubstanceItem>[] = [
        {
            id: 'substance',
            header: 'Substance',
            cell: ({ row }) => {
                return (
                    <div className='capitalize'>{row.original.substance}</div>
                );
            },
        },
        {
            id: 'cas',
            header: 'CAS',
            cell: ({ row }) => {
                return (
                    <div className='text-xs capitalize'>
                        {row.original.cas || '--'}
                    </div>
                );
            },
        },
        {
            id: 'weight',
            header: 'Weight',
            accessorFn: (row) => row.weight.toFixed(2) || '--',
        },
        {
            id: 'category',
            header: 'Category',
            cell: ({ row }) => {
                return <div>{row.original.category.join(', ')}</div>;
            },
        },
        {
            id: 'shippedOn',
            header: 'Shipped On',
            accessorFn: (row) => {
                if (row.shippedOn) {
                    return DateTime.fromJSDate(row.shippedOn).toFormat(
                        'yyyy-MM-dd'
                    );
                }

                return '--';
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                return (
                    <div className='flex gap-2'>
                        <GanttChartSquare
                            size={16}
                            className='cursor-pointer hover:scale-125'
                            onClick={() => {
                                const parsedName = encodeURIComponent(
                                    row.original.name
                                );
                                const parsedCas = encodeURIComponent(
                                    row.original.cas || '--'
                                );

                                navigate(
                                    `/report/substances/${parsedName}/${parsedCas}`
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const memoizedOrders = useMemo(() => {
        const substanceMap = new Map<string, SubstanceItem>();
        const startTimestamp = startDate ? new Date(startDate).getTime() : 0;
        const endTimestamp = endDate ? new Date(endDate).getTime() : Infinity;
        const lowercaseSubstanceName = substanceName.toLowerCase();

        orders?.data.forEach((order) => {
            console.log(order);
            const shippedOn =
                order.shippedOn && order.shippedOn.length > 0
                    ? order.shippedOn[0]
                    : null;

            const parsedShippedOn = shippedOn
                ? new Date(shippedOn).getTime()
                : null;

            if (
                parsedShippedOn &&
                (parsedShippedOn < startTimestamp ||
                    parsedShippedOn > endTimestamp)
            ) {
                return; // Skip this product if it's outside the date range
            }

            order?.versions?.forEach((version) => {
                version?.manifest?.forEach((manifest) => {
                    if (
                        category &&
                        !(
                            manifest?.product?.category?.name
                                ?.toLocaleLowerCase()
                                .includes(category.toLocaleLowerCase()) ||
                            manifest?.product?.category?.code
                                ?.toLocaleLowerCase()
                                .includes(category.toLocaleLowerCase())
                        )
                    ) {
                        console.debug(
                            `Skipping product ${manifest?.product?.name} because it's not in the ${category} category`
                        );
                        return;
                    }

                    manifest?.product?.versions?.forEach((version) => {
                        version.billOfMaterials?.forEach((bom) => {
                            bom.material.compositions?.forEach(
                                (composition) => {
                                    if (
                                        lowercaseSubstanceName &&
                                        !composition.substanceName
                                            ?.toLowerCase()
                                            .includes(lowercaseSubstanceName)
                                    ) {
                                        return; // Skip this composition if it doesn't match the substance name filter
                                    }

                                    const key = `${
                                        composition.substanceName ||
                                        composition.name
                                    } - ${composition.substanceCode}`;
                                    const existingItem = substanceMap.get(key);

                                    const category =
                                        manifest.product.category?.name ||
                                        manifest.product.category?.code;

                                    if (existingItem) {
                                        existingItem.weight +=
                                            composition.weight || 0;

                                        if (
                                            category &&
                                            !existingItem.category.includes(
                                                category
                                            )
                                        ) {
                                            existingItem.category.push(
                                                category
                                            );
                                        }
                                    } else {
                                        substanceMap.set(key, {
                                            substance:
                                                composition.substanceName || '',
                                            cas:
                                                composition.substanceCode || '',
                                            weight: composition.weight || 0,
                                            lastUpdatedOn:
                                                manifest.product.lastUpdatedOn,
                                            shippedOn: parsedShippedOn
                                                ? new Date(parsedShippedOn)
                                                : null,
                                            name:
                                                composition.substanceName ||
                                                composition.name ||
                                                '',
                                            category: category
                                                ? [category]
                                                : [],
                                        });
                                    }
                                }
                            );
                        });
                    });
                });
            });
        });

        let filteredSubstances = Array.from(substanceMap.values());

        if (casNumber) {
            filteredSubstances = filteredSubstances.filter((item) =>
                item.cas.toLowerCase().includes(casNumber.toLowerCase())
            );
        }

        if (thresholdWeight > 0) {
            filteredSubstances = filteredSubstances.filter(
                (item) => item.weight >= thresholdWeight
            );
        }

        return filteredSubstances;
    }, [
        orders,
        startDate,
        endDate,
        thresholdWeight,
        substanceName,
        category,
        casNumber,
    ]);

    return (
        <ReusableTable
            columns={columns}
            data={memoizedOrders || []}
            title='Substance Report'
            loading={isLoading}
        />
    );
}
