import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { CircleHelp, GanttChartSquare } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MaterialComposition } from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import {
    PurchaseOrderStatus,
    PurchaseOrderStatusLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-status.enum';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { ReusableTable } from 'src/app/pages-v2/Purchases/reusable-table';
import { useContextStore } from 'src/app/stores/context-store';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import { Button } from 'src/components/ui/button';

export function SubstanceDetails() {
    const { name, cas } = useParams<{ name: string; cas: string }>();
    const navigate = useNavigate();
    const { workspace: w } = useContextStore();

    const handleRedirectToDetails = useCallback(
        (id: string) => {
            navigate(`/purchases/${id}`);
        },
        [navigate]
    );

    const getPurchaseOrderItemCategory = useCallback(
        (order: PurchaseModel) => {
            if (order.versions) {
                const version = order.versions.find(
                    (v) => (v.manifest || []).length > 0
                );
                if (version?.manifest) {
                    const manifest = version.manifest[0];
                    if (!manifest) {
                        return '--';
                    } else if (manifest.product) {
                        const purchaseable = manifest.product;
                        if (purchaseable.category) {
                            const categoryBase =
                                w?.features.supplyChainLoaderType === 0
                                    ? purchaseable.category.code
                                          ?.split('::_-')
                                          ?.pop()
                                    : purchaseable.category.code
                                          ?.split('::')
                                          ?.shift();
                            if (categoryBase) {
                                return categoryBase
                                    .split(' ')
                                    .map((i) =>
                                        i
                                            .split('_')
                                            .map(
                                                (j) =>
                                                    j
                                                        .slice(0, 1)
                                                        .toUpperCase() +
                                                    j.slice(1).toLowerCase()
                                            )
                                            .join(' ')
                                    )
                                    .join(' ');
                            } else {
                                return '--';
                            }
                        } else {
                            return '--';
                        }
                    } else {
                        return '--';
                    }
                }
            } else {
                return '--';
            }
        },
        [w?.features.supplyChainLoaderType]
    );

    const getOrderTotal = useCallback(
        (order: PurchaseModel) => {
            const version = order.versions[order.versions.length - 1];

            if (version) {
                const total = version.manifest.reduce((acc, item) => {
                    return acc + item.ppu * item.quantity;
                }, 0);

                if (w?.features?.supplyChainLoaderType === 0) {
                    return total.toFixed(2);
                } else {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });
                    return formatter.format(total);
                }
            }
        },
        [w?.features.supplyChainLoaderType]
    );

    const columns: ColumnDef<PurchaseModel>[] = useMemo(() => {
        return [
            {
                id: 'po number',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>PO Number</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) => {
                    return generateOrderTitle(row);
                },
                cell: ({ row }) => {
                    return (
                        <span
                            onClick={() => {
                                handleRedirectToDetails(row.original._id || '');
                            }}
                            className='cursor-pointer text-xs text-blue-800 hover:underline'
                        >
                            {generateOrderTitle(row.original)}
                        </span>
                    );
                },
                enableSorting: true,
            },
            {
                id: 'case',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>Case</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) =>
                    row.reference.find((r) => r.source === 'externalDataId')
                        ?.value,
                enableSorting: true,
            },
            {
                id: 'supplier',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>Supplier</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) => {
                    return row.supplier.seller.name;
                },
                enableSorting: true,
            },
            {
                id: 'primary Category',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>Primary Category</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) => {
                    const category = getPurchaseOrderItemCategory(row);

                    return category;
                },
                enableSorting: true,
            },
            {
                id: 'item Number',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>Item Number</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) => {
                    const order = row;
                    const version = order.versions[order.versions.length - 1];

                    if (!version) return '--';
                    if (version.manifest.length < 1) return '--';

                    const primaryItem = version?.manifest[0];

                    return primaryItem?.product?.name;
                },
                cell: ({ row }) => {
                    const order = row.original;
                    const version = order.versions[order.versions.length - 1];

                    if (!version) return '--';
                    if (version.manifest.length < 1) return '--';

                    const itemCount = version.manifest.length;
                    const primaryItem = version?.manifest[0];

                    const customerItemNo =
                        primaryItem?.product?.externalReferences?.find(
                            (x) => x.source === 'customer'
                        )?.value;
                    const lfItemNo =
                        primaryItem?.product?.externalReferences?.find(
                            (x) => x.source === 'externalDataId'
                        )?.value;
                    const lfItemNoOld = Reflect.get(
                        primaryItem?.product?.externalReferences?.find(
                            (x) => x.source === 'externalDataId'
                        ) ?? {},
                        'reference'
                    );

                    return (
                        <div className='flex items-center gap-2'>
                            {customerItemNo ||
                                lfItemNo ||
                                lfItemNoOld ||
                                primaryItem?.product?.name}
                            <div className='has-tooltip'>
                                <span className='tooltip -mt-8 flex flex-col rounded bg-white px-4 py-2 shadow'>
                                    {version.manifest.map((item) => {
                                        const customerItem =
                                            item?.product?.externalReferences?.find(
                                                (x) => x.source === 'customer'
                                            )?.value;
                                        const lfItem =
                                            item?.product?.externalReferences?.find(
                                                (x) =>
                                                    x.source ===
                                                    'externalDataId'
                                            )?.value;
                                        const lfItemOld = Reflect.get(
                                            item?.product?.externalReferences?.find(
                                                (x) =>
                                                    x.source ===
                                                    'externalDataId'
                                            ) ?? {},
                                            'reference'
                                        );

                                        return (
                                            <div
                                                className='list-item'
                                                key={item?.product?._id}
                                            >
                                                {customerItem ||
                                                    lfItem ||
                                                    lfItemOld ||
                                                    item?.product?.name}
                                            </div>
                                        );
                                    })}
                                </span>
                                {itemCount > 1 && (
                                    <span>
                                        <CircleHelp size={16} />
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                },
                enableSorting: true,
            },
            {
                id: 'currency',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>Currency</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) => row.currency,
                enableSorting: true,
            },
            {
                id: 'buy Date',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>Buy Date</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) => {
                    return DateTime.fromJSDate(
                        new Date(row.createdOn)
                    ).toFormat('yyyy-MM-dd');
                },
                enableSorting: true,
            },
            {
                id: 'total',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>Total</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) => getOrderTotal(row),
                enableSorting: true,
            },
            {
                id: 'quantity',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>Quantity</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) => {
                    const version = row.versions[row.versions.length - 1];

                    if (version) {
                        return version.manifest.reduce((acc, item) => {
                            return acc + item.quantity;
                        }, 0);
                    }
                },
                enableSorting: true,
            },
            {
                id: 'status',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>Status</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) =>
                    PurchaseOrderStatusLabels[
                        row.status as PurchaseOrderStatus
                    ],
                enableSorting: true,
            },
            {
                id: 'updated On',
                header: ({ column }) => {
                    return (
                        <Button
                            variant='ghost'
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === 'asc'
                                )
                            }
                            className='flex items-center space-x-1 text-xs'
                        >
                            <span>Updated On</span>
                            <CaretSortIcon className={`h-4 w-4`} />
                        </Button>
                    );
                },
                accessorFn: (row) => {
                    if (!row.lastUpdatedOn) return '--';

                    return DateTime.fromJSDate(
                        new Date(row.lastUpdatedOn)
                    ).toFormat('yyyy-MM-dd');
                },
                enableSorting: true,
            },
            {
                id: 'actions',
                header: 'Actions',
                disableSorting: true,
                enableColumnFilter: false,
                cell: ({ row }) => {
                    return (
                        <Button
                            variant='ghost'
                            data-testid='details-button'
                            onClick={() =>
                                handleRedirectToDetails(row.original._id || '')
                            }
                        >
                            <GanttChartSquare className='h-4 w-4' />
                        </Button>
                    );
                },
            },
        ];
    }, [handleRedirectToDetails, getOrderTotal, getPurchaseOrderItemCategory]);

    const { data: orders, isLoading: ordersLoading } = usePurchases({
        page: 0,
        limit: 0,
        draft: false,
    });

    const ordersWithCas = useMemo(() => {
        return orders?.data.filter((order) => {
            // Iterate through each version of the order

            const exists = order?.versions?.some((version) => {
                return version?.manifest?.some((manifest) => {
                    return manifest?.product?.versions?.some((version) => {
                        return version?.billOfMaterials?.some((bom) => {
                            return bom.material.compositions?.some(
                                (composition) => {
                                    console.log(composition);
                                    if (
                                        composition?.substanceCode === cas ||
                                        composition?.substanceCode === name ||
                                        composition?.substanceName === cas ||
                                        composition?.substanceCode === name ||
                                        composition?.name === cas ||
                                        composition?.name === name
                                    ) {
                                        return true;
                                    }

                                    return false;
                                }
                            );
                        });
                    });
                });
            });

            return exists;
        });
    }, [orders, cas, name]);

    const substance: { name: string; cas: string } | null = useMemo(() => {
        let compositionWithSubstance: MaterialComposition | null = null;

        ordersWithCas?.forEach((order) => {
            order?.versions?.forEach((version) => {
                version?.manifest?.forEach((manifest) => {
                    manifest?.product.versions?.forEach((version) => {
                        version.billOfMaterials?.forEach((bom) => {
                            bom.material.compositions?.forEach((c) => {
                                if (
                                    c.substanceCode === cas ||
                                    c.substanceCode === name ||
                                    c.substanceName === cas ||
                                    c.substanceName === name ||
                                    c.name === cas ||
                                    c.name === name
                                ) {
                                    compositionWithSubstance = c;
                                }
                            });
                        });
                    });
                });
            });
        });

        if (!compositionWithSubstance) {
            return null;
        }

        const composition = compositionWithSubstance as MaterialComposition;

        return {
            name: composition.name,
            cas:
                composition.substanceCode ||
                composition.substanceName ||
                composition.name,
        };
    }, [cas, name, ordersWithCas]);

    return (
        <div className='flex h-full flex-col gap-6'>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 grid grid-cols-12 gap-6 rounded-lg border border-gray-200 bg-white px-4 py-4'>
                    <div className='col-span-12 flex w-full items-center justify-between'>
                        <span className='text-xs font-bold underline decoration-red-800 decoration-2 underline-offset-8'>
                            General Information
                        </span>
                    </div>

                    <div className='col-span-8 grid grid-cols-3 gap-4'>
                        <DetailLabel title='Name'>
                            {substance?.name}
                        </DetailLabel>
                        <DetailLabel title='CAS'>{substance?.cas}</DetailLabel>
                    </div>
                </div>
            </div>

            <ReusableTable
                columns={columns}
                data={ordersWithCas ?? []}
                loading={ordersLoading}
                title='Orders'
            />
        </div>
    );
}
