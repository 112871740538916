import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import {
    ArrowRight,
    Check,
    DownloadCloud,
    LoaderCircle,
    X,
} from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { WorkspaceModel } from 'src/app/_api_adb2c/product/category/category.model';
import { useApproval } from 'src/app/_api_adb2c/product/product-approval/hooks/use-approval';
import { useApproveApprovalRequest } from 'src/app/_api_adb2c/product/product-approval/hooks/use-approve-approval-request';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import {
    ProductBillOfMaterialEntry,
    ProductStatusLabels,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { VirtualTable } from 'src/app/components-v2/virtual-table';
import {
    ConsolidatedComposition,
    VirtualWorkspaceComposition,
} from 'src/app/pages-v2/Products/Details/Substances';
import Logo from 'src/assets/lf-logo.png';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';
import { ProductRejection } from './product-rejection';
import {
    RenderCircle,
    useDownloadBundle,
} from '../../Materials/Details/Substance';

export function ProductApproval() {
    const { id } = useParams<{ id: string }>();
    const [isRejected, setIsRejected] = useState(false);

    const { data: request } = useApproval(id || '');
    const { mutateAsync: approve, isLoading } = useApproveApprovalRequest();

    const { data } = useProduct(request?.product || '', request?.workspace);
    const [items, setItems] = useState<ConsolidatedComposition[]>([]);
    console.log('this is the data', data);

    const { downloadBundle } = useDownloadBundle();

    const download = useCallback(
        async (item: ConsolidatedComposition) => {
            const fileIds = item.sdsFileId;

            if (fileIds?.length === 0) {
                toast.error('No SDS file found, no action taken');
                return;
            }

            const name = `Sds File - ${item.name}`;

            const request = fileIds?.map((id) => ({
                _id: id,
                name,
            }));

            await downloadBundle(request || []);
        },
        [downloadBundle]
    );

    const isCompleted = useMemo(() => {
        if (!request) return false;

        return request.approvedOn || request.rejectedOn;
    }, [request]);

    const externalReference = useMemo(() => {
        const reference = data?.externalReferences?.find((x) => {
            return x.source === 'externalReferenceCode';
        })?.value;

        return reference;
    }, [data]);

    const productNumber = useMemo(() => {
        const reference = data?.externalReferences?.find((x) => {
            return x.source === 'externalDataId';
        })?.value;

        return reference;
    }, [data]);

    const latestVersion = useMemo(() => {
        const version = data?.versions?.[data.versions.length - 1];

        if (!version) {
            return undefined;
        }

        return version;
    }, [data]);

    const memoizedMaterials = useMemo<ProductBillOfMaterialEntry[]>(() => {
        const version = data?.versions?.[data.versions.length - 1];

        if (!version) return [];

        return version.billOfMaterials || [];
    }, [data]);

    const grossWeight = useMemo(() => {
        const version = data?.versions?.[data?.versions.length - 1];

        if (!version) return 0;

        const grossWeight = version.billOfMaterials?.reduce((acc, x) => {
            return acc + (x.weight || 0) / (x.consumption || 1);
        }, 0);

        return grossWeight || 0;
    }, [data]);

    const memoizedSubstances = useMemo(() => {
        const version = data?.versions?.[data?.versions.length - 1];

        if (!version) return [];
        if (!version.billOfMaterials) return [];

        const response =
            version.billOfMaterials
                .map((x) => x.material.compositions)
                .filter((x) => x)
                .flatMap((x) => {
                    return x?.map((y) => {
                        return {
                            ...y,
                        };
                    });
                }) || [];

        return response as VirtualWorkspaceComposition[];
    }, [data]);

    const totalWeight = useMemo(() => {
        if (!data) return 0;
        if (!data.versions?.length) return 0;

        const latestVersion = data.versions[data.versions.length - 1];
        if (!latestVersion) return 0;

        return latestVersion?.billOfMaterials?.reduce(
            (acc, curr) => acc + (curr.weight || 0) / (curr.consumption || 1),
            0
        );
    }, [data]);

    const isMaxRatio = useMemo(() => {
        if (!memoizedSubstances) return false;

        const ratio = memoizedSubstances.reduce((acc, curr) => {
            return acc + curr.weight;
        }, 0);

        return ratio >= grossWeight;
    }, [memoizedSubstances, grossWeight]);

    const triggerApproval = useCallback(() => {
        if (!request) return;

        if (!isMaxRatio) {
            toast.error(`The ratio of the substance is not equal to 100%`);
            return;
        }

        approve({
            item: request._id,
        });
    }, [approve, request, isMaxRatio]);

    const consolidatedItemColumns: ColumnDef<ConsolidatedComposition>[] =
        useMemo(() => {
            const columns: ColumnDef<ConsolidatedComposition>[] = [
                {
                    id: 'select',
                    size: 100,
                    header: ({ table }) => (
                        <Checkbox
                            checked={table.getIsAllPageRowsSelected()}
                            onCheckedChange={(value) =>
                                table.toggleAllPageRowsSelected(!!value)
                            }
                            aria-label='Select all'
                            data-testid='select-all-button'
                        />
                    ),
                    cell: ({ row }) => {
                        if (row.depth > 0) {
                            return <></>;
                        }

                        return (
                            <div className='flex items-center gap-4'>
                                <Checkbox
                                    checked={row.getIsSelected()}
                                    onCheckedChange={(value) =>
                                        row.toggleSelected(!!value)
                                    }
                                    aria-label='Select row'
                                    data-testid='select-row-button'
                                />

                                {row.subRows.length > 0 && (
                                    <div onClick={() => row.toggleExpanded()}>
                                        <ArrowRight
                                            size={16}
                                            className={
                                                row.getIsExpanded()
                                                    ? 'rotate-90 transform'
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    },
                    enableSorting: false,
                    enableHiding: false,
                },
                {
                    id: 'substance',
                    header: 'Substance',
                    accessorFn: (row) => row.substanceCode || row.substanceName,
                    cell: ({ row }) => {
                        return (
                            <div className='flex items-center gap-2 capitalize'>
                                <span>
                                    {row.original.substanceCode ||
                                        row.original.substanceName}
                                </span>
                            </div>
                        );
                    },
                },
                {
                    id: 'description',
                    header: 'Description',
                    accessorFn: (row) => {
                        return row.substanceName || row.name;
                    },
                    cell: ({ row }) => {
                        return (
                            <div className='flex items-center gap-2 capitalize'>
                                <span>
                                    {row.original.substanceName ||
                                        row.original.name}
                                </span>
                            </div>
                        );
                    },
                },
                {
                    id: 'weigth',
                    header: 'Weight',
                    accessorFn: (row) => row.weight.toFixed(5),
                },
                {
                    id: 'ratio',
                    header: 'Ratio',
                    cell: ({ row }) => {
                        const ratio =
                            (row.original.weight / (totalWeight || 0)) * 100;

                        return ratio.toFixed(2);
                    },
                },
                {
                    id: 'supplier',
                    header: 'Supplier',
                    accessorFn: (row) => row.supplier,
                    cell: ({ row }) => {
                        const distinctSuppliers = row.original.supplier?.filter(
                            (supplier, index, self) =>
                                index ===
                                self.findIndex(
                                    (item) => item._id === supplier._id
                                )
                        );

                        const distinctFirstSupplier = distinctSuppliers?.[0];
                        const distinctSuppliersLength =
                            distinctSuppliers?.length || 0;

                        return (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger>
                                        {distinctFirstSupplier?.name}{' '}
                                        {distinctSuppliersLength > 1 && (
                                            <span className='text-xs'>
                                                +{distinctSuppliersLength - 1}
                                            </span>
                                        )}
                                    </TooltipTrigger>

                                    <TooltipContent>
                                        <div className='flex flex-col gap-2'>
                                            {distinctSuppliers?.map(
                                                (supplier) => (
                                                    <div className='flex items-center gap-2'>
                                                        <span>
                                                            {supplier.name}
                                                        </span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        );
                    },
                },
                {
                    id: 'manMadeSubstance',
                    header: 'MMS',
                    cell: ({ row }) => {
                        if (row.depth > 0) {
                            return <></>;
                        }

                        return row.original.isManMade ? (
                            <RenderCircle color='completed' />
                        ) : (
                            <RenderCircle color='empty' />
                        );
                    },
                },
                {
                    id: 'reachCode',
                    header: 'REG',
                    accessorFn: (row) => row.substanceRegCode,
                    cell: ({ row }) => {
                        if (row.depth > 0) {
                            return <></>;
                        }

                        return row.original.substanceRegCode ? (
                            <RenderCircle color='completed' />
                        ) : (
                            <RenderCircle color='empty' />
                        );
                    },
                },
                {
                    id: 'SDS',
                    header: 'SDS',
                    accessorFn: (row) => row.sdsFileId,
                    cell: ({ row }) => {
                        if (row.depth > 0) {
                            return <></>;
                        }

                        console.log(row.original.sdsFileId);

                        return row.original.sdsFileId &&
                            row.original.sdsFileId.length > 0 ? (
                            <RenderCircle color='completed' />
                        ) : (
                            <RenderCircle color='empty' />
                        );
                    },
                },
                {
                    id: 'actions',
                    header: 'Actions',
                    cell: ({ row }) => {
                        if (row.depth > 0) {
                            return <></>;
                        }

                        return (
                            <div className='flex gap-4'>
                                <DownloadCloud
                                    size={16}
                                    className={cn(
                                        !row.original.sdsFileId
                                            ? 'cursor-not-allowed'
                                            : '',
                                        'hover:scale-125'
                                    )}
                                    onClick={() => {
                                        if (row.original.sdsFileId) {
                                            download(row.original);
                                        }
                                    }}
                                />
                            </div>
                        );
                    },
                },
            ];

            return columns;
        }, [download, totalWeight]);

    const materialColumns: ColumnDef<ProductBillOfMaterialEntry>[] = [
        {
            id: 'component',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>Component</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.name;
            },
        },
        {
            id: 'description',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>Description</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.description ?? row.material.description;
            },
        },
        {
            id: 'consumption',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>Consumption</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.consumption;
            },
        },
        {
            id: 'uom',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>UOM</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.unitOfMeasurement
                    ? ProductUomLabels[row.unitOfMeasurement as ProductUom]
                    : '';
            },
        },
        {
            id: 'weight',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>Weight</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.weight;
            },
        },
        {
            id: 'cost',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>Cost (USD)</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.materialCost;
            },
        },
    ];

    useEffect(() => {
        const latest = data?.versions?.[data.versions.length - 1];
        if (!latest) return;

        const consolidatedCompositions: Record<
            string,
            ConsolidatedComposition
        > = {};

        latest.billOfMaterials?.forEach((bill) => {
            bill?.material?.compositions?.forEach((composition) => {
                const name = composition.substanceCode || composition.name;
                const supplier =
                    composition.supplier as unknown as WorkspaceModel;

                if (!consolidatedCompositions[name]) {
                    consolidatedCompositions[name] = {
                        ...composition,
                        name: composition.name,
                        weight: composition.weight || 0,
                        actualWeight: composition.actualWeight || 0,
                        supplier: supplier ? [supplier] : undefined,
                        sdsFileId: composition.sdsFileId
                            ? [composition.sdsFileId]
                            : [],
                    };

                    return;
                }

                consolidatedCompositions[name].weight += composition?.weight;
                consolidatedCompositions[name].actualWeight +=
                    composition?.actualWeight;

                if (composition.sdsFileId) {
                    consolidatedCompositions[name].sdsFileId?.push(
                        composition.sdsFileId
                    );
                }
            });
        });

        setItems(Object.values(consolidatedCompositions));
    }, [data]);

    if (!data) return <></>;

    return (
        <>
            <div className='h-screen w-screen px-4'>
                <div className='w-[240px]'>
                    <img
                        className='w-4/5'
                        alt='Application Logo'
                        src={Logo}
                    ></img>
                </div>

                <div className='m-auto grid h-[90%] w-4/5 grid-cols-1 gap-4'>
                    <div className='grid grid-cols-1 justify-between rounded-lg bg-white px-4 py-2 shadow-xl'>
                        <div className='flex items-center justify-between py-2'>
                            <span className='text-xs font-bold underline decoration-red-800 decoration-2 underline-offset-2'>
                                Product Information
                            </span>

                            {isCompleted ? (
                                <div>
                                    <span className='text-xs font-bold'>
                                        Status:{' '}
                                        <span
                                            className={cn(
                                                'text-xs font-bold',
                                                request?.approvedOn
                                                    ? 'text-green-600'
                                                    : 'text-red-600'
                                            )}
                                        >
                                            {request?.approvedOn
                                                ? 'Approved'
                                                : 'Rejected'}
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <div className='flex gap-2'>
                                    <Button
                                        className='flex items-center gap-2'
                                        variant='ghost'
                                        size='sm'
                                        onClick={
                                            () => triggerApproval()
                                            // triggerApproval({
                                            //     item: request?._id || '',
                                            // })
                                        }
                                    >
                                        {isLoading ? (
                                            <>
                                                <LoaderCircle
                                                    size={16}
                                                    className='animate-spin'
                                                />
                                                <span>Loading...</span>
                                            </>
                                        ) : (
                                            <>
                                                <Check size='16' />
                                                <span>Approve</span>
                                            </>
                                        )}
                                    </Button>

                                    <Button
                                        className='flex items-center gap-2'
                                        variant='ghost'
                                        size='sm'
                                        disabled={isLoading}
                                        onClick={() => setIsRejected(true)}
                                    >
                                        <X size='16' />
                                        <span>Reject</span>
                                    </Button>
                                </div>
                            )}
                        </div>

                        <div className='grid grid-cols-4 gap-4'>
                            <DetailLabel title='Product Number'>
                                {productNumber}
                            </DetailLabel>
                            <DetailLabel title='Unit of Measurement'>
                                {data.unitOfMeasurement
                                    ? ProductUomLabels[
                                          data.unitOfMeasurement as ProductUom
                                      ]
                                    : '--'}
                            </DetailLabel>
                            <DetailLabel title='External Sys Number'>
                                {externalReference}
                            </DetailLabel>
                            <DetailLabel title='Available Processes'></DetailLabel>

                            <DetailLabel title='Product Name'>
                                {data.name}
                            </DetailLabel>
                            <DetailLabel title='Gross Weight'>
                                {grossWeight}
                                {/* {latestVersion?.specifications?.weight} */}
                            </DetailLabel>
                            <DetailLabel
                                title='Variant'
                                className='col-span-2'
                            ></DetailLabel>

                            <DetailLabel title='Product Family'></DetailLabel>
                            <DetailLabel title='UPC/EAN'>
                                {data.upc}
                            </DetailLabel>
                            <DetailLabel title='Season/Collection'>
                                {data.collection}
                            </DetailLabel>
                            <DetailLabel title='Status'>
                                {data.status
                                    ? ProductStatusLabels[data?.status]
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='Product Category'>
                                {data?.category?.name || data.category?.code}
                            </DetailLabel>
                            <DetailLabel title='HS Code'>
                                {data?.hsCode}
                            </DetailLabel>
                            <DetailLabel title='Cost' className='col-span-2'>
                                {latestVersion?.costOfMaterials}
                            </DetailLabel>
                        </div>
                    </div>

                    <div className='flex h-full flex-col'>
                        <div className='flex w-full items-center justify-between rounded-t-lg border bg-red-800 px-4 py-2'>
                            <span className='text-sm font-bold text-white'>
                                Bill of Material
                            </span>

                            <div className='flex gap-4'></div>
                        </div>

                        <VirtualTable
                            columns={materialColumns}
                            data={memoizedMaterials}
                            parentClassName='flex-1 max-h-full'
                        />
                    </div>

                    <div className='flex h-full flex-col '>
                        <div className='flex w-full items-center justify-between rounded-t-lg border bg-red-800 px-4 py-2'>
                            <span className='text-sm font-bold text-white'>
                                Bill of Substance
                            </span>

                            <div className='flex gap-4'></div>
                        </div>

                        <VirtualTable
                            columns={consolidatedItemColumns}
                            data={items}
                            parentClassName='flex-1 max-h-full'
                            subRowsField='subCompositions'
                        />
                    </div>
                </div>
            </div>

            <ProductRejection
                open={isRejected}
                approvalId={request?._id || ''}
                onClose={() => setIsRejected(false)}
            />
        </>
    );
}
