import { ColumnDef } from '@tanstack/react-table';
import axios from 'axios';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import {
    DownloadCloud,
    Edit,
    Plus,
    StepForward,
    Trash,
    Upload,
} from 'lucide-react';
import { useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useSearchParams } from 'react-router-dom';
import { useAttachmentDownload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-download';
import { useMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-material';
import { MaterialComposition } from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { SupplierModel } from 'src/app/_api_adb2c/purchase/suppliers/models/supplier.model';
import { FileModel } from 'src/app/_api_adb2c/workspace/file/file.model';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { Checkbox } from 'src/components/ui/checkbox';
import countries from 'src/infrastructure/config/data/countries.json';
import { cn } from 'src/lib/utils';
import { AddSubstanceV3 } from './AddSubstance/add-substance-v3';
import { DeleteSubstance } from './DeleteSubstance';
import EditSubstanceV2 from './EditSubstance';
import { SubstanceImport } from './ImportSubstance/substance-import';

interface Props {
    material?: string;
}

export interface VirtualComposition
    extends Omit<MaterialComposition, 'supplier'> {
    supplier?: SupplierModel;
    document?: FileModel;
    netWeight?: number;
}

export const colorScheme: Record<string, string> = {
    optional: 'bg-gray-400',
    empty: 'bg-red-600',
    partial: 'bg-yellow-400',
    completed: 'bg-green-600',
};

interface CircleProps {
    color: 'optional' | 'empty' | 'partial' | 'completed';
}

export function RenderCircle({ color }: CircleProps) {
    return (
        <div className={cn('h-2 w-2 rounded-full', colorScheme[color])}></div>
    );
}

export function useDownload() {
    const { mutateAsync } = useAttachmentDownload();

    const download = async (id: string, name: string, autoDownload = true) => {
        const response = await mutateAsync({
            body: { id },
            diversion: '1',
        });

        if (autoDownload) {
            const blob = await axios.get(response.token, {
                responseType: 'blob',
            });

            saveAs(blob.data, name);
        }

        return response;
    };

    return { download };
}

interface DownloadBundleInterface {
    _id: string;
    name: string;
}

interface DownloadBundleResponse {
    url: string;
    name: string;
}

export function useDownloadBundle() {
    const { mutateAsync } = useAttachmentDownload();

    const downloadBundle = async (bundle: DownloadBundleInterface[]) => {
        const links: DownloadBundleResponse[] = [];

        await Promise.allSettled(
            bundle.map(async (item) => {
                const token = await mutateAsync({
                    body: { id: item._id },
                });

                const response = await axios.get(token.token, {
                    responseType: 'blob',
                });

                links.push({
                    url: response.data,
                    name: item.name,
                });
            })
        );

        const zip = new JSZip();
        links.forEach((link) => {
            zip.file(link.name, link.url);
        });

        zip.generateAsync({ type: 'blob' }).then((zipFile) => {
            const currentDate = new Date().getTime();
            const fileName = `bundle-${currentDate}.zip`;

            return saveAs(zipFile, fileName);
        });
    };

    return { downloadBundle };
}

export function MaterialBillOfSubstance({ material }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [mode, setMode] = useState<'create' | 'edit' | 'delete' | 'import'>();
    const [selectedComposition, setSelectedComposition] =
        useState<VirtualComposition>();

    const { data } = useMaterial(material || '', delegateId);
    const { data: suppliers } = useSuppliers(delegateId);

    const { download } = useDownload();

    const memoizedData = useMemo(() => {
        if (!data?.compositions) return [];

        return data.compositions.map((comp) => {
            const supplier = suppliers?.find(
                (supplier) => supplier.seller._id === comp.supplier
            );

            return {
                ...comp,
                supplier: supplier,
            };
        });
    }, [data, suppliers]);

    const columns: ColumnDef<VirtualComposition>[] = useMemo(() => {
        const columns: ColumnDef<VirtualComposition>[] = [
            {
                id: 'select',
                size: 100,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllPageRowsSelected()}
                        onCheckedChange={(value) =>
                            table.toggleAllPageRowsSelected(!!value)
                        }
                        aria-label='Select all'
                        data-testid='select-all-button'
                    />
                ),
                cell: ({ row }) => {
                    if (row.depth > 0) {
                        return <></>;
                    }

                    return (
                        <div className='flex items-center gap-4'>
                            <Checkbox
                                checked={row.getIsSelected()}
                                onCheckedChange={(value) =>
                                    row.toggleSelected(!!value)
                                }
                                aria-label='Select row'
                                data-testid='select-row-button'
                            />
                        </div>
                    );
                },
                enableSorting: false,
                enableHiding: false,
            },
            {
                id: 'substance',
                header: 'Substance',
                cell: ({ row }) => {
                    return (
                        <div className='flex items-center gap-2 capitalize'>
                            <span>
                                {row.original.substanceName ||
                                    row.original.name}
                            </span>
                        </div>
                    );
                },
            },
            {
                id: 'description',
                header: 'Description',
                accessorFn: (row) => {
                    return row.substanceCode || '--';
                },
            },
            {
                id: 'ratio',
                header: 'Ratio',
                accessorFn: (row) =>
                    (
                        (row.weight / (data?.specifications?.weight || 0)) *
                        100
                    )?.toFixed(2),
                cell: ({ row }) => {
                    const info = row.original;
                    const parentRow = row.getParentRow();
                    const weight =
                        (info.weight ? info.weight : info?.projectedWeight) ||
                        0;

                    let ratio = 0;

                    ratio = parentRow
                        ? (weight / parentRow.original.weight) * 100
                        : (weight / (data?.specifications?.weight || 0)) * 100;

                    return ratio?.toFixed(2);
                },
            },
            {
                id: 'weigth',
                header: 'Weight',
                accessorFn: (row) => {
                    return row.weight
                        ? row.weight?.toFixed(5)
                        : row?.projectedWeight?.toFixed(5);
                },
            },
            {
                id: 'supplier',
                header: 'Supplier',
                accessorFn: (row) => row.supplier?.seller.name,
            },
            {
                id: 'COO',
                header: 'COO',
                cell: ({ row }) => {
                    if (row.depth > 0) {
                        return <></>;
                    }

                    const country = countries.find(
                        (country) =>
                            country['alpha-2'] ===
                            row.original.supplier?.seller.name
                    );

                    return (
                        <ReactCountryFlag
                            countryCode={
                                row.original.supplier?.seller.country || ''
                            }
                            className='text-xl'
                            aria-label={row.original.supplier?.seller.country}
                            title={country?.name}
                        />
                    );
                },
            },
            {
                id: 'manMadeSubstance',
                header: 'MMS',
                cell: ({ row }) => {
                    if (row.depth > 0) {
                        return <></>;
                    }

                    return row.original.isManMade ? (
                        <RenderCircle color='completed' />
                    ) : (
                        <RenderCircle color='empty' />
                    );
                },
            },
            {
                id: 'reachCode',
                header: 'REG',
                accessorFn: (row) => row.substanceRegCode,
                cell: ({ row }) => {
                    if (row.depth > 0) {
                        return <></>;
                    }

                    return row.original.substanceRegCode ? (
                        <RenderCircle color='completed' />
                    ) : (
                        <RenderCircle color='empty' />
                    );
                },
            },
            {
                id: 'SDS',
                header: 'SDS',
                accessorFn: (row) => row.sdsFileId,
                cell: ({ row }) => {
                    if (row.depth > 0) {
                        return <></>;
                    }

                    return row.original.sdsFileId ? (
                        <RenderCircle color='completed' />
                    ) : (
                        <RenderCircle color='empty' />
                    );
                },
            },
            {
                id: 'actions',
                cell: ({ row }) => {
                    if (row.depth > 0) {
                        return <></>;
                    }

                    return (
                        <div className='flex gap-4'>
                            {row.original.isDraft && (
                                <StepForward
                                    size={16}
                                    className='hover:scale-125'
                                    onClick={() => {
                                        setSelectedComposition(row.original);
                                        setMode('edit');
                                    }}
                                />
                            )}

                            {!row.original.isDraft && (
                                <Edit
                                    size={16}
                                    className='hover:scale-125'
                                    onClick={() => {
                                        setSelectedComposition(row.original);
                                        setMode('edit');
                                    }}
                                />
                            )}

                            <DownloadCloud
                                size={16}
                                className={cn(
                                    !row.original.sdsFileId
                                        ? 'cursor-not-allowed'
                                        : '',
                                    'hover:scale-125'
                                )}
                                onClick={() => {
                                    if (row.original.sdsFileId) {
                                        download(
                                            row.original.sdsFileId,
                                            row.original.name
                                        );
                                    }
                                }}
                            />

                            <Trash
                                size={16}
                                className='hover:scale-125'
                                onClick={() => {
                                    setSelectedComposition(row.original);
                                    setMode('delete');
                                }}
                            />
                        </div>
                    );
                },
            },
        ];

        return columns;
    }, [data, download]);

    if (!data) return <></>;

    console.log(memoizedData);

    return (
        <>
            <div className='flex h-full flex-col'>
                <TableV2
                    columns={columns}
                    data={memoizedData}
                    subRowsField='subCompositions'
                    label='Bill of Substance'
                    actions={[
                        {
                            id: 'add',
                            icon: <Plus size={16} />,
                            onClick: () => setMode('create'),
                            label: 'Add Substance',
                            condition: {
                                isAllowed:
                                    !!data.specifications?.weight &&
                                    data.specifications?.weight > 0,
                                message:
                                    'You need to set the weight of the material first',
                            },
                        },
                        {
                            id: 'import',
                            icon: <Upload size={16} />,
                            onClick: () => setMode('import'),
                            label: 'Import Substance',
                        },
                    ]}
                />
            </div>

            {mode === 'create' && (
                <AddSubstanceV3
                    open={mode === 'create'}
                    materialId={data._id}
                    onClose={() => setMode(undefined)}
                />
            )}

            {mode === 'edit' && (
                <EditSubstanceV2
                    open={mode === 'edit'}
                    composition={selectedComposition}
                    materialId={material || ''}
                    onClose={() => setMode(undefined)}
                />
            )}

            {mode === 'delete' && selectedComposition && (
                <DeleteSubstance
                    open={mode === 'delete'}
                    onClose={() => setMode(undefined)}
                    materialId={material || ''}
                    composition={selectedComposition}
                />
            )}

            {mode === 'import' && (
                <SubstanceImport
                    open={mode === 'import'}
                    materialId={material || ''}
                    onClose={() => setMode(undefined)}
                />
            )}
        </>
    );
}
