import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { useCallback, useEffect, useState } from 'react';
import {
    FeatureGroup,
    MapContainer,
    Marker,
    Polyline,
    Popup,
    TileLayer,
} from 'react-leaflet';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

export const customMarkerUserPos = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
    iconSize: [25, 30],
    iconAnchor: [15, 30],
    popupAnchor: [2, -40],
});

L.Marker.prototype.options.icon = DefaultIcon;

interface Props {
    data: PurchaseModel;
    traces: PurchaseOrderTraceModel[];
}

interface TrackingCoordinates {
    _id: string;
    name: string;
    coordinates: number[];
    color: string;
    parent?: string;
    parentCoordinates?: number[];
}

// function generateRandomCoordinates(
//     xMin: number,
//     xMax: number,
//     yMin: number,
//     yMax: number
// ): [number, number] {
//     const x = Math.random() * (xMax - xMin) + xMin;
//     const y = Math.random() * (yMax - yMin) + yMin;
//     return [x, y];
// }

export function PurchaseTracking({ data, traces }: Props) {
    const [trackingCoordinates, setTrackingCoordinates] = useState<
        TrackingCoordinates[]
    >([]);

    const generateColor = useCallback(() => {
        const rgb: string[] = [];

        for (let i = 0; i < 3; i++) {
            rgb.push(Math.floor(Math.random() * 255).toString());
        }

        return `rgb(${rgb.join(',')})`;
    }, []);

    useEffect(() => {
        const coordinates: TrackingCoordinates[] = [];
        const coordinateMap: Record<string, [number, number]> = {};

        if (data.supplier?.seller?.location?.coordinates) {
            // const coordinate = generateRandomCoordinates(-100, 100, -100, 100);
            const coordinate = data?.supplier?.seller?.location?.coordinates;
            if (!coordinate) return;

            coordinates.push({
                _id: data._id,
                name: data.supplier.seller.name,
                coordinates: coordinate,
                color: generateColor(),
            });

            coordinateMap[data.supplier.seller.name] = [
                coordinate[0],
                coordinate[1],
            ];
        }

        traces.forEach((trace) => {
            if (trace?.supplier?.location?.coordinates) {
                let coordinate = trace?.supplier?.location?.coordinates;
                if (!coordinate) return;
                // let coordinate = generateRandomCoordinates(
                //     -100,
                //     100,
                //     -100,
                //     100
                // );

                if (!coordinateMap[trace.supplier.name]) {
                    coordinateMap[trace.supplier.name] = [
                        coordinate[0],
                        coordinate[1],
                    ];
                } else {
                    coordinate = coordinateMap[trace.supplier.name];
                }

                let parent = '';

                if (trace.parent) {
                    if (trace.parent === data._id) {
                        parent = data.supplier.seller.name;
                    }

                    const parentTrace = traces.find(
                        (x) => x._id === trace.parent
                    );

                    if (parentTrace) {
                        parent = parentTrace.supplier.name;
                    }
                }

                coordinates.push({
                    _id: trace._id,
                    name: trace.supplier.name,
                    coordinates: coordinate,
                    color: generateColor(),
                    parent: parent,
                    parentCoordinates: parent
                        ? coordinateMap[parent] || undefined
                        : undefined,
                });
            }
        });

        console.log(coordinates);
        setTrackingCoordinates(coordinates);
    }, [data, traces, generateColor]);

    return (
        <div className='flex h-full flex-col gap-4'>
            <MapContainer
                className='h-full w-full'
                zoom={3}
                center={[35.86166, 104.195397]}
                attributionControl={false}
                scrollWheelZoom={false}
                boundsOptions={{ padding: [50, 50] }}
                minZoom={2}
            >
                <TileLayer
                    attribution='&amp;copy <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />

                {trackingCoordinates.map((coordinate, index) => {
                    return (
                        <Marker
                            key={index}
                            position={[
                                coordinate.coordinates[0],
                                coordinate.coordinates[1],
                            ]}
                            icon={customMarkerUserPos}
                        >
                            <Popup>{coordinate.name}</Popup>

                            {coordinate.parent &&
                                coordinate.parentCoordinates &&
                                coordinate.coordinates && (
                                    <FeatureGroup>
                                        <Polyline
                                            key={index}
                                            color={coordinate.color}
                                            positions={[
                                                [
                                                    coordinate
                                                        .parentCoordinates[0],
                                                    coordinate
                                                        .parentCoordinates[1],
                                                ],
                                                [
                                                    coordinate.coordinates[0],
                                                    coordinate.coordinates[1],
                                                ],
                                            ]}
                                        ></Polyline>
                                    </FeatureGroup>
                                )}
                        </Marker>
                    );
                })}
            </MapContainer>
        </div>
    );
}
