import { zodResolver } from '@hookform/resolvers/zod';
import {
    ColumnDef,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';
import { Trash } from 'lucide-react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useCreateDocument } from 'src/app/_api_adb2c/workspace/documents/hooks/use-create-document';
import { DataTableV2 } from 'src/app/components-v2/table';
import { DateInput } from 'src/app/components/Form/DateInput';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Dropzone } from 'src/app/pages/Fulfillments/dropzone';
import { useContextStore } from 'src/app/stores/context-store';
import { Button } from 'src/components/ui/button';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';
import { BaseDialog } from 'src/app/components-v2/base-dialog';
import { useWorkspaceCertificationTypes } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-certification-types';

interface Props {
    open: boolean;
    onClose: () => void;
}

const documentSchema = z.object({
    name: z.string(),
    source: z.string(),
    serial: z.string(),
    issuedOn: z.date(),
    contentType: z.string(),
    size: z.number(),
    type: z.string(),
    file: z.any(),
});

const formSchema = z.object({
    documents: z.array(documentSchema),
});

export const CreateCertificate = ({ open, onClose }: Props) => {
    const { workspace, user } = useContextStore();

    // const types = ['ISO_9001'];
    const { data: types } = useWorkspaceCertificationTypes();
    const { mutateAsync: create } = useCreateDocument();
    const { mutateAsync: upload } = useAttachmentUpload();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            documents: [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'documents',
    });

    const reset = () => {
        form.reset();
        onClose();
    };

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        await Promise.all(
            data.documents.map(async (doc) => {
                const token = await upload({
                    assetType: 'document',
                    file: {
                        contentType: doc.contentType,
                        genre: 'document',
                        name: doc.name,
                        size: doc.size,
                        uploadedBy: user?._id || '',
                        workspace: workspace?._id || '',
                    },
                });

                await axios.put(token.token, doc.file, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': doc.contentType,
                    },
                });

                const splittedAssetName = token.attachment.assetName.split('/');

                const imageId =
                    splittedAssetName[splittedAssetName.length - 1].split(
                        '.'
                    )[0];

                return await create({
                    body: {
                        documents: [
                            {
                                file: imageId,
                                isPrivate: false,
                                type: doc.type,
                                source: doc.source,
                                serial: doc.serial,
                                issued: doc.issuedOn,
                            },
                        ],
                        extensions: [
                            {
                                isCertificate: true,
                                name: doc.name,
                                translatedName: [doc.name],
                                translatedDescription: [],
                            },
                        ],
                    },
                });
            })
        );

        reset();
    };

    const columns: ColumnDef<z.infer<typeof documentSchema>>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row }) => {
                return <TextInput inputName={`documents[${row.index}].name`} />;
            },
        },
        {
            id: 'auditOrganization',
            header: 'Audit Organization',
            cell: ({ row }) => {
                return (
                    <TextInput inputName={`documents[${row.index}].source`} />
                );
            },
        },
        {
            id: 'certification',
            header: 'Certification',
            cell: ({ row }) => {
                return (
                    <SelectInput
                        name={`documents[${row.index}].type`}
                        options={(types || []).map((type) => ({
                            label: type.name,
                            value: type.id,
                        }))}
                    />
                );
            },
        },
        {
            id: 'certificateNumber',
            header: 'Certificate Number',
            cell: ({ row }) => {
                return (
                    <TextInput inputName={`documents[${row.index}].serial`} />
                );
            },
        },
        {
            id: 'issuedDate',
            header: 'Issued Date',
            cell: ({ row }) => {
                return (
                    <DateInput name={`documents[${row.index}].issuedDate`} />
                );
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            size: 10,
            cell: ({ row }) => {
                return (
                    <Button onClick={() => remove(row.index)} variant='outline'>
                        <Trash size={16} />
                    </Button>
                );
            },
        },
    ];

    const table = useReactTable({
        data: fields,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <BaseDialog
            open={open}
            title='Create Certification'
            onClose={reset}
            sizes='lg'
        >
            <Dropzone
                handleFileChange={(file) => {
                    append({
                        name: file.file.name,
                        contentType: file.contentType,
                        source: '',
                        serial: '',
                        type: '',
                        issuedOn: new Date(),
                        size: file.file.size,
                        file: file.file,
                    });
                }}
            />

            <Form {...form}>
                <form id='my-form' onSubmit={form.handleSubmit(onSubmit)}>
                    <DataTableV2 hideHeader table={table} />
                </form>
            </Form>
        </BaseDialog>
    );
};
