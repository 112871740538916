import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { ProductApprovalRequestRejectDto } from '../request/product-approval-request-reject-dto';
import { PRODUCT_APPROVAL_QUERY_KEY } from './product-approval-query-keys';
import { useProductApprovalService } from './use-product-approval-service';

export function useRejectApprovalRequest() {
    const { service } = useProductApprovalService();
    const context = useContextStore();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            body,
            item,
            delegateId,
        }: {
            item: string;
            body: ProductApprovalRequestRejectDto;
            delegateId?: string;
        }) => {
            return await service.reject(
                delegateId || context.workspace?._id || '',
                item,
                body
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                PRODUCT_APPROVAL_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                )
            );

            toast.success('Product approval created successfully');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to create product approval: ${error.message}`);
        },
    });
}
