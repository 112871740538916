import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { LoaderCircle, Plus, SaveAll } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import { useAttachmentDownload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-download';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-material';
import { useUpdateMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-update-material';
import {
    MaterialComposition,
    ProductMaterialDataObject,
} from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { TreeInputV2 } from 'src/app/components/Form/TreeInputV2';
import { useContextStore } from 'src/app/stores/context-store';
import { Button } from 'src/components/ui/button';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { z } from 'zod';
import { VirtualComposition } from '..';
import { AboutManualSubstance } from '../AddSubstance/about-manual-substance';
import { AboutSubstance } from '../AddSubstance/about-substance';
import { AddSubstanceConfirmation } from '../AddSubstance/add-substance-confirmation';
import { AddSubstanceDocumentation } from '../AddSubstance/add-substance-documentation';
import { AddSubstanceSource } from '../AddSubstance/add-substance-source';
import {
    schema,
    substanceItemSchema,
    treeOptions,
} from '../AddSubstance/add-substance-v3';
import { AddManualSubstance } from '../AddSubstance/add-manual-substance';
import { AddSupplier } from 'src/app/pages-v2/Suppliers/add-supplier';
import CompoundJson from 'src/infrastructure/config/data/compound.json';
import FibreJson from 'src/infrastructure/config/data/fibre.json';

interface Props {
    open: boolean;
    composition?: VirtualComposition;
    materialId: string;
    onClose: () => void;
}

export default function EditSubstanceV2({
    open,
    onClose,
    composition,
    materialId,
}: Props) {
    const [searchParams] = useSearchParams();
    const context = useContextStore();
    const delegateId = searchParams.get('delegateId') || '';
    const { data: material } = useMaterial(materialId);

    const [currentStep, setCurrentStep] = useState(1);
    const [isSaveDraft, setIsSaveDraft] = useState(false);
    const [addManualSubstance, setAddManualSubstance] = useState(false);

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        mode: 'onChange',
    });

    const isAddMode = form.watch('supplier') === 'new';
    const substanceType = form.watch('substanceType');
    const isManual = form.watch('isManual');
    const isMixedSubstance = form.watch('isMixedSubstance');
    const projectedWeight = form.watch('projectedWeight');

    const { fields, append, remove, replace } = useFieldArray({
        control: form.control,
        name: 'items',
    });

    const { mutateAsync: download } = useAttachmentDownload();
    const { mutateAsync: upload, isLoading: isUploading } =
        useAttachmentUpload();
    const { mutateAsync: update, isLoading: isUpdating } = useUpdateMaterial();

    const substance = useMemo(() => {
        if (substanceType && substanceType.length > 0) {
            const parentType = substanceType[0].value;
            if (parentType === 'manual') return;

            const selectedType = substanceType[substanceType.length - 1];

            return selectedType;
        }
    }, [substanceType]);

    const generateComposition = (
        composition: z.infer<typeof schema>,
        isDraft?: boolean,
        fileId?: string
    ): MaterialComposition => {
        const isManual = composition.substanceType?.some(
            (x) => x.value === 'manualInput'
        );

        return {
            name: isManual ? composition.name || '' : substance?.label || '',
            supplier: composition.supplier,
            substanceCode: composition.cas,
            substanceName: isManual
                ? composition.name || ''
                : substance?.label || '',
            isManMade: composition.isManMade,
            substanceRegCode: composition.reachRegistrationNumber,
            sdsFileId: fileId,
            subCompositions: composition.items
                ? composition.items.map((substance) => {
                      return {
                          name: substance.name,
                          substanceCode: substance.cas,
                          isManmade: substance.isManMade,
                          weight: substance.projectedWeight || 0,
                          actualWeight: substance.projectedWeight || 0,
                          subCompositions: substance.composition,
                      };
                  })
                : [],
            weight: composition.projectedWeight || 0,
            actualWeight: composition.projectedWeight || 0,
            stepInDraft: isDraft ? currentStep : undefined,
            isDraft: isDraft || false,
        };
    };

    const updateFormValues = useCallback(async () => {
        if (!composition) return;

        let file;
        if (composition.sdsFileId) {
            const fileResponse = await download({
                delegateId: delegateId,
                body: {
                    id: composition.sdsFileId,
                },
            });

            const fileBlob = await axios.get(fileResponse.token, {
                responseType: 'blob',
            });

            file = new File(
                [fileBlob.data],
                fileResponse.attachment.originalName,
                {
                    type: fileResponse.attachment.contentType,
                }
            );
        }

        let type: { label: string; value: string }[] = [];

        const flattendedTree = treeOptions.flatMap((x) => {
            return x.children.map((y) => {
                return {
                    ...y,
                    parent: x,
                };
            });
        });

        const existingType = flattendedTree.find(
            (x) =>
                x.value === composition?.substanceCode ||
                x.label === composition?.substanceName
        );

        type = existingType
            ? [
                  existingType.parent,
                  { label: existingType.label, value: existingType.value },
              ]
            : [
                  { label: 'Manual', value: 'manual' },
                  { label: 'Manual Input', value: 'manualInput' },
              ];

        if (composition && form) {
            console.log('composition', composition);
            form.reset({
                _id: composition._id,
                cas: composition.substanceCode,
                supplier: composition.supplier?.seller?._id,
                reachRegistrationNumber: composition.substanceRegCode,
                isManual: type.some((x) => x.value === 'manualInput'),
                projectedWeight: composition.weight,
                isManMade: composition.isManMade,
                isMixedSubstance:
                    (composition.subCompositions || []).length > 0,
                percentage: Number(
                    (composition.weight /
                        (material?.specifications?.weight || 0)) *
                        100
                ),
                items: composition.subCompositions?.map((x) => {
                    console.log('x', x);
                    const isFibre = FibreJson.find(
                        (y) => y.value === x.substanceCode || y.name === x.name
                    );
                    const isCompound = CompoundJson.find(
                        (y) => y.cas === x.substanceCode || y.name === x.name
                    );

                    return {
                        ...x,
                        cas: x.substanceCode,
                        projectedWeight: x.weight,
                        percentage: Number(
                            (x.weight / (composition.weight || 0)) * 100
                        ),
                        composition: x.subCompositions,
                        isManual: isFibre || isCompound ? true : false,
                        isManMade: x.isManMade || false,
                    };
                }),
                // supplierRegCode: composition.substanceReference,
                substanceType: type,
                name: composition.name || composition.substanceName,
                files: file ? file : undefined,
            });

            if (composition.stepInDraft && composition.isDraft) {
                setCurrentStep(composition.stepInDraft);
            }
        }
    }, [composition, form, material, download, delegateId]);

    const saveDraft = async (params: z.infer<typeof schema>) => {
        if (!material) return;

        setIsSaveDraft(true);

        let isNewFile: boolean = false;
        let sdsFileId = composition?.sdsFileId;
        const request: ProductMaterialDataObject = {
            ...material,
        };

        if (composition?.sdsFileId) {
            const fileResponse = await download({
                delegateId: delegateId,
                body: {
                    id: composition.sdsFileId,
                },
            });

            const fileBlob = await axios.get(fileResponse.token, {
                responseType: 'blob',
            });

            const newFile = new File(
                [fileBlob.data],
                fileResponse.attachment.originalName,
                {
                    type: fileResponse.attachment.contentType,
                }
            );

            isNewFile = newFile.size !== params.files?.size ? true : false;
        } else if (
            params.files &&
            params.files.size > 0 &&
            !composition?.sdsFileId
        ) {
            isNewFile = true;
        }

        if (isNewFile && params.files) {
            const fileRequest: FileCreateDataObject = {
                contentType: params.files?.type,
                name: params.files?.name,
                genre: 'temporary',
                size: params.files?.size,
                uploadedBy: context.user?._id || '',
                workspace: delegateId || context.workspace?._id || '',
            };

            const fileResp = await upload({
                assetType: 'material',
                delegateId: delegateId,
                file: fileRequest,
            });

            await axios.put(fileResp.token, params.files, {
                headers: {
                    'Content-Type': params.files.type,
                    'x-ms-blob-type': 'BlockBlob',
                },
            });

            sdsFileId = getFileIdFromAssetName(fileResp.attachment.assetName);
        }

        if (!params.files) {
            sdsFileId = undefined;
        }

        const updatedCompositions = (material.compositions || []).map(
            (comp) => {
                const isCurrent = comp._id === params._id;

                if (isCurrent) {
                    return generateComposition(params, true, sdsFileId);
                }

                return comp;
            }
        );

        await update({
            delegateId: delegateId,
            id: material._id,
            body: {
                ...request,
                compositions: updatedCompositions,
            },
        });

        reset();
    };

    const onSubmit = async (params: z.infer<typeof schema>) => {
        if (!material) return;

        const request: ProductMaterialDataObject = { ...material };
        let isNewFile: boolean = false;
        let sdsFileId = composition?.sdsFileId;

        if (composition?.sdsFileId) {
            const fileResponse = await download({
                delegateId: delegateId,
                body: {
                    id: composition.sdsFileId,
                },
            });

            const fileBlob = await axios.get(fileResponse.token, {
                responseType: 'blob',
            });

            const newFile = new File(
                [fileBlob.data],
                fileResponse.attachment.originalName,
                {
                    type: fileResponse.attachment.contentType,
                }
            );

            isNewFile = newFile.size !== params.files?.size ? true : false;
        } else if (
            params.files &&
            params.files.size > 0 &&
            !composition?.sdsFileId
        ) {
            isNewFile = true;
        }

        if (isNewFile && params.files) {
            const fileRequest: FileCreateDataObject = {
                contentType: params.files?.type,
                name: params.files?.name,
                genre: 'temporary',
                size: params.files?.size,
                uploadedBy: context.user?._id || '',
                workspace: delegateId || context.workspace?._id || '',
            };

            const fileResp = await upload({
                assetType: 'material',
                delegateId: delegateId,
                file: fileRequest,
            });

            await axios.put(fileResp.token, params.files, {
                headers: {
                    'Content-Type': params.files.type,
                    'x-ms-blob-type': 'BlockBlob',
                },
            });

            sdsFileId = getFileIdFromAssetName(fileResp.attachment.assetName);
        }

        if (!params.files) {
            sdsFileId = undefined;
        }

        const updatedCompositions = (material.compositions || []).map(
            (comp) => {
                const isCurrent = comp._id === params._id;

                if (isCurrent) {
                    return generateComposition(params, false, sdsFileId);
                }

                return comp;
            }
        );

        await update({
            delegateId: delegateId,
            id: material._id,
            body: {
                ...request,
                compositions: updatedCompositions,
            },
        });

        reset();
    };

    const reset = () => {
        form.reset();
        setIsSaveDraft(false);
        setCurrentStep(1);
        onClose();
    };

    const appendSubstance = (data: z.infer<typeof substanceItemSchema>) => {
        append(data);
    };

    useEffect(() => {
        updateFormValues();
    }, [updateFormValues]);

    useEffect(() => {
        if (composition?.stepInDraft) {
            setCurrentStep(composition.stepInDraft);
        }
    }, [composition]);

    return (
        <>
            <DialogV2
                open={open}
                onClose={onClose}
                title='Edit Substance'
                size='xl'
                form={form}
                formName='edit-substance-form'
                onSubmit={async (value) => {
                    await onSubmit(value);
                }}
                isStepDialog
                currentStep={currentStep}
                onCurrentStepChange={setCurrentStep}
                isLoading={isUpdating || isUploading}
                extraControls={
                    <Button
                        type='button'
                        size='sm'
                        variant='outline'
                        className='flex min-w-[100px] gap-2'
                        onClick={() => saveDraft(form.getValues())}
                        loading={isSaveDraft}
                    >
                        {isSaveDraft ? (
                            <LoaderCircle size={16} className='animate-spin' />
                        ) : (
                            <SaveAll
                                size={16}
                                className='cursor-pointer hover:scale-125'
                            />
                        )}
                        Save Draft
                    </Button>
                }
                steps={[
                    {
                        title: 'About Your Substance',
                        description: 'Provide information about the substance',
                        content: (
                            <div className='grid grid-cols-2 gap-2'>
                                {
                                    <TreeInputV2
                                        name='substanceType'
                                        label='Substance Type'
                                        options={treeOptions}
                                        onChange={(value) => {
                                            form.setValue('cas', value.value);

                                            if (value.value === 'manualInput') {
                                                form.setValue('isManual', true);
                                            } else {
                                                form.setValue(
                                                    'isManual',
                                                    false
                                                );
                                            }
                                        }}
                                    />
                                }

                                {isManual && substanceType && (
                                    <AboutManualSubstance
                                        material={material}
                                        items={fields}
                                        remove={remove}
                                        replace={replace}
                                    />
                                )}

                                {!isManual && substanceType && (
                                    <AboutSubstance material={material} />
                                )}
                            </div>
                        ),
                        pageControls: (
                            <>
                                {isManual && isMixedSubstance && (
                                    <Button
                                        variant='outline'
                                        type='button'
                                        onClick={() =>
                                            setAddManualSubstance(true)
                                        }
                                        size='sm'
                                        className='flex min-w-[100px] gap-2'
                                    >
                                        {isSaveDraft ? (
                                            <LoaderCircle
                                                size={16}
                                                className='animate-spin'
                                            />
                                        ) : (
                                            <Plus size={16} />
                                        )}
                                        Add Substance
                                    </Button>
                                )}
                            </>
                        ),
                    },
                    {
                        title: 'Identify Your Source',
                        description: 'Identify the source of the substance',
                        content: <AddSubstanceSource />,
                    },
                    {
                        title: 'Include Documentation',
                        description:
                            'Include the documentation for the substance',
                        content: <AddSubstanceDocumentation name='files' />,
                    },
                    {
                        title: 'Verify and Confirm',
                        description: 'Verify and confirm the substance',
                        content: <AddSubstanceConfirmation />,
                    },
                ]}
            ></DialogV2>

            <AddManualSubstance
                open={addManualSubstance}
                onClose={() => setAddManualSubstance(false)}
                projectedWeight={projectedWeight}
                append={appendSubstance}
            />

            {isAddMode && (
                <AddSupplier
                    open={isAddMode}
                    onClose={() => form.setValue('supplier', '')}
                />
            )}
        </>
    );
}
