import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useWorkspaceService } from './use-workspace-service';

interface CertificationType {
    id: string;
    name: string;
    body: string;
    type: string;
}

export function useWorkspaceCertificationTypes() {
    const { service } = useWorkspaceService();

    return useQuery<CertificationType[]>({
        queryKey: ['workspace-certification-types'],
        queryFn: async () => {
            return await service.getCertificationTypes();
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(
                `Failed to fetch workspace certification types: ${error.message}`
            );
        },
    });
}
