import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef } from '@tanstack/react-table';
import { PlusIcon, Trash } from 'lucide-react';
import { useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useMaterials } from 'src/app/_api_adb2c/product/material/hooks/use-materials';
import { useComposeProduct } from 'src/app/_api_adb2c/product/product/hooks/use-componse-product';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import {
    UsageNature,
    UsageNatureLabels,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import { ProductBillOfMaterialDataObject } from 'src/app/_api_adb2c/product/product/requests/product-bill-of-material-data-object';
import { ProductUpdateDataObject } from 'src/app/_api_adb2c/product/product/requests/product-update-data-object';
import { ProductVersionCreateDataObject } from 'src/app/_api_adb2c/product/product/requests/product-version-create-data-object';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { Button } from 'src/components/ui/button';
import { z } from 'zod';

interface Props {
    open: boolean;
    productId: string;
    onClose: () => void;
}

const itemSchema = z.object({
    consumption: z.coerce
        .number()
        .min(0, 'Consumption must be greater than 0')
        .nonnegative('Consumption cannot be negative'),
    material: z.string(),
    name: z.string().optional(),
    unitOfMeasurement: z.string().optional(),
    usageNature: z.string().optional(),
});

const schema = z.object({
    items: z.array(itemSchema),
});

export function AddProductMaterial({ open, productId, onClose }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: product } = useProduct(productId, delegateId);
    const { data: materials } = useMaterials(delegateId);
    const { mutateAsync: compose, isLoading } = useComposeProduct();

    const onSubmit = async (params: z.infer<typeof schema>) => {
        if (!product) return;

        const versions = product.versions;
        const latestVersion = product.versions?.[product.versions.length - 1];

        const consolidatedComponents = (
            latestVersion?.billOfMaterials || []
        ).map((bom) => {
            return {
                materialCost: bom.materialCost,
                name: bom.name,
                source: bom.source,
                unitOfMeasurement: bom.unitOfMeasurement,
                consumption: bom.consumption,
                resource: bom.resource,
                weight: bom.weight,
                usageNature: bom.usageNature,
            } as ProductBillOfMaterialDataObject;
        });

        params.items.forEach((item) => {
            const material = materials?.data?.find(
                (y) => y._id === item.material
            );

            consolidatedComponents.push({
                materialCost: material?.unitCost || 0,
                name: material?.name || '',
                source: 'local',
                unitOfMeasurement: material?.unitOfMeasurement || '',
                consumption: item.consumption,
                resource: material?._id || '',
                weight: material?.weight || 0,
                usageNature: item.usageNature,
            });
        });

        const costOfMaterials = consolidatedComponents.reduce(
            (acc, curr) => acc + curr.materialCost,
            0
        );

        const componentWeight = consolidatedComponents.reduce(
            (acc, curr) => acc + (curr.weight || 0),
            0
        );

        const versionRequest: ProductVersionCreateDataObject = {
            version: (versions?.length || 0) + 1,
            billOfMaterials: consolidatedComponents,
            costOfMaterials: costOfMaterials,
            specifications: {
                height: latestVersion?.specifications?.height || 0,
                width: latestVersion?.specifications?.width || 0,
                weight: componentWeight,
                depth: latestVersion?.specifications?.depth || 0,
            },
            releasedOn: new Date(),
        };

        const request: ProductUpdateDataObject = {
            versions: [versionRequest],
            name: product.name,
        };

        await compose({
            id: product._id,
            body: request,
        });

        reset();
    };

    const reset = () => {
        onClose();
    };

    const existingMaterials = useMemo(() => {
        if (!product) return [];

        const latestVersion = product.versions?.[product.versions.length - 1];
        if (!latestVersion) return [];

        return latestVersion?.billOfMaterials?.map((x) => x?.material?._id);
    }, [product]);

    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            items: [],
        },
    });

    const { fields, append, replace } = useFieldArray({
        control: form.control,
        name: 'items',
    });

    const handleAppend = () => {
        append({
            consumption: 0,
            material: '',
            unitOfMeasurement: '',
            usageNature: '',
        });
    };

    const handleRemove = (index: number) => {
        const newItems = fields.filter((_, i) => i !== index);
        replace(newItems);
    };

    const columns: ColumnDef<z.infer<typeof itemSchema>>[] = [
        {
            id: 'material',
            header: 'Material',
            cell: ({ row }) => {
                return (
                    <SelectInputV2
                        name={`items.${row.index}.material`}
                        className='w-full'
                        options={(materials?.data || [])
                            .filter((x) => !existingMaterials?.includes(x._id))
                            .map((x) => ({
                                label: x.name,
                                value: x._id,
                            }))}
                    />
                );
            },
        },
        {
            id: 'consumption',
            header: 'Consumption',
            cell: ({ row }) => {
                return (
                    <TextInputV2
                        name={`items.${row.index}.consumption`}
                        placeholder='0'
                    />
                );
            },
        },
        {
            id: 'uom',
            header: 'UOM',
            cell: ({ row }) => {
                return (
                    <SelectInputV2
                        name={`items.${row.index}.unitOfMeasurement`}
                        options={Object.values(ProductUom).map((x) => ({
                            label: ProductUomLabels[x],
                            value: x,
                        }))}
                    />
                );
            },
        },
        {
            id: 'usageNature',
            header: 'Usage',
            cell: ({ row }) => {
                return (
                    <SelectInputV2
                        name={`items.${row.index}.usageNature`}
                        options={Object.values(UsageNature).map((x) => ({
                            label: UsageNatureLabels[x],
                            value: x,
                        }))}
                    />
                );
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <div className='flex h-full items-center gap-2'>
                    <Trash
                        onClick={() => handleRemove(row.index)}
                        size={16}
                        className='cursor-pointer hover:scale-125'
                    />
                </div>
            ),
        },
    ];

    return (
        <DialogV2
            title='Add Product Bill Of Materials'
            open={open}
            onClose={reset}
            isStepDialog
            form={form}
            size='lg'
            isLoading={isLoading}
            onSubmit={onSubmit}
            steps={[
                {
                    title: 'Add Product Bill Of Materials',
                    description: 'Add the bill of materials for this product',
                    content: (
                        <TableV2
                            columns={columns}
                            data={fields}
                            tableContainerClassName='h-full'
                            tableHeaderClassName='bg-red-800 text-white'
                            disableTooltips
                        />
                    ),
                },
            ]}
            extraControls={
                <Button
                    onClick={() => handleAppend()}
                    type='button'
                    variant='outline'
                    size='sm'
                >
                    <PlusIcon size={16} />
                </Button>
            }
        />
    );
}
