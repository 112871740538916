export const BASE_PRODUCT_APPROVAL_QUERY_KEY = 'product-approval';
export const BASE_PRODUCT_APPROVAL_BY_WORKSPACE_QUERY_KEY =
    'product-approval-by-workspace';
export const BASE_PRODUCT_APPROVAL_BY_APPROVER_QUERY_KEY =
    'product-approval-by-approver';

export const PRODUCT_APPROVAL_QUERY_KEY = (workspace: string) => [
    BASE_PRODUCT_APPROVAL_QUERY_KEY,
    workspace,
];

export const PRODUCT_APPROVAL_BY_WORKSPACE_QUERY_KEY = (workspace: string) => [
    BASE_PRODUCT_APPROVAL_BY_WORKSPACE_QUERY_KEY,
    workspace,
];

export const PRODUCT_APPROVAL_BY_APPROVER_QUERY_KEY = (workspace: string) => [
    BASE_PRODUCT_APPROVAL_BY_APPROVER_QUERY_KEY,
    workspace,
];
