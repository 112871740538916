import { AccordionContent } from '@radix-ui/react-accordion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSalesOrderBatch } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-order-batch';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { useBatchRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-batch-rulesets';
import { WorkspaceRulesetModel } from 'src/app/_api_adb2c/workspace/ruleset/ruleset.model';
import { useWorkspaceService } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-service';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import {
    Accordion,
    AccordionItem,
    AccordionTrigger,
} from 'src/components/ui/accordion';
import { PurchaseDocumentTable } from './purchase-document-table';
import { Loader2 } from 'lucide-react';
import { Skeleton } from 'src/components/ui/skeleton';

interface Props {
    purchaseId: string;
}

export interface PurchaseDocumentItem {
    purchase: PurchaseModel | PurchaseOrderTraceModel;
    salesOrder?: SalesOrderModel;
    ruleset?: WorkspaceRulesetModel;
    workspace?: WorkspaceModel;
    requiredDocuments: string[];
    tier: number;
    isTrace?: boolean;
}

export const getRequiredDocuments = (
    processes: string[],
    ruleset?: WorkspaceRulesetModel
) => {
    if (!ruleset) return [];

    const documents: string[] = [];

    ruleset.rules.forEach((rule) => {
        processes.forEach((process) => {
            if (rule.processes.includes(process)) {
                documents.push(rule.document);
            }
        });
    });

    return documents;
};

export function PurchaseDocuments({ purchaseId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [items, setItems] = useState<PurchaseDocumentItem[][]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { service: workspaceService } = useWorkspaceService();

    const { data: purchase, isLoading: isPurchaseLoading } = usePurchase(
        purchaseId,
        delegateId,
        '1'
    );

    const { data: traces, isLoading: isTraceLoading } = usePurchaseTrace(
        purchaseId,
        delegateId,
        '1'
    );

    const memoizedPurchaseIds = useMemo(() => {
        if (!traces) return [];

        const purchaseIds: string[] = [purchaseId];

        traces.forEach((trace) => {
            purchaseIds.push(trace._id);
        });

        return purchaseIds;
    }, [purchaseId, traces]);

    const { data: salesOrders } = useSalesOrderBatch(
        memoizedPurchaseIds,
        delegateId,
        !isPurchaseLoading && !isTraceLoading
    );

    const memoizedRulesets = useMemo(() => {
        if (!purchase) return [];
        if (!traces) return [];

        const rules: string[] = [];

        purchase.rules?.forEach((rule) => {
            rules.push(rule);
        });

        traces.forEach((trace) => {
            trace.rules?.forEach((rule) => {
                rules.push(rule);
            });
        });

        return Array.from(new Set(rules));
    }, [purchase, traces]);

    const { data: rulesets } = useBatchRulesets(memoizedRulesets);

    useEffect(() => {
        console.log('items are as so', items);
        console.log(items);
        console.log('sales orders are as so', salesOrders);
    }, [items, salesOrders]);

    const generateItems = useCallback(async () => {
        if (!purchase) return [];
        if (!traces) return [];
        if (!rulesets) return [];

        setIsLoading(true);

        const items: PurchaseDocumentItem[] = [];

        const purchaseSalesOrder = salesOrders?.find(
            (x) => x.purchaseOrderId === purchase._id
        );

        const purchaseRuleset = rulesets.find(
            (x) => x._id === purchase.rules?.[0]
        );

        const purchaseWorkspace: WorkspaceModel = await workspaceService.get(
            purchase.workspace,
            '1'
        );

        const processes =
            purchase.processes ||
            purchaseWorkspace.company.demographics.processes;

        items.push({
            purchase: purchase,
            salesOrder: purchaseSalesOrder,
            ruleset: purchaseRuleset,
            workspace: purchaseWorkspace,
            requiredDocuments: getRequiredDocuments(processes, purchaseRuleset),
            tier: 0,
            isTrace: false,
        });

        await Promise.all(
            traces.map(async (trace) => {
                const traceSalesOrder = salesOrders?.find(
                    (x) => x.purchaseOrderId === trace._id
                );

                const traceRuleset = rulesets.find(
                    (x) => x._id === trace.rules?.[0]
                );

                const traceWorkspace = await workspaceService.get(
                    trace.workspace,
                    '1'
                );

                const processes =
                    trace.processes ||
                    traceWorkspace.company.demographics.processes;

                items.push({
                    purchase: trace,
                    salesOrder: traceSalesOrder,
                    ruleset: traceRuleset,
                    workspace: traceWorkspace,
                    requiredDocuments: getRequiredDocuments(
                        processes,
                        traceRuleset
                    ),
                    isTrace: true,
                    tier: trace.depth + 1,
                });
            })
        );

        const consolidatedItems: PurchaseDocumentItem[][] = [];
        items.forEach((item) => {
            if (!consolidatedItems[item.tier]) {
                consolidatedItems[item.tier] = [];
            }

            consolidatedItems[item.tier].push(item);
        });

        setItems(consolidatedItems);
        setIsLoading(false);
    }, [purchase, salesOrders, traces, rulesets, workspaceService]);

    useEffect(() => {
        generateItems();
    }, [generateItems]);

    if (isLoading) {
        return (
            <div className='h-full w-full'>
                <Skeleton className='flex h-full w-full items-center justify-center gap-2'>
                    <Loader2 size={16} className='animate-spin' />
                    <span className='items-center justify-center text-center text-sm'>
                        Loading Document Data...
                    </span>
                </Skeleton>
            </div>
        );
    }

    return (
        <div className='h-full'>
            <Accordion
                type='single'
                collapsible
                className='flex h-full flex-col'
            >
                {items.map((item, index) => {
                    return (
                        <AccordionItem
                            value={`tier-${index}`}
                            key={index}
                            className='data-[state=closed]:flex-none data-[state=open]:flex-1'
                        >
                            <AccordionTrigger className='rounded-lg border border-gray-400 bg-gray-200 px-4 py-2 capitalize'>
                                Tier {index + 1}
                            </AccordionTrigger>

                            <AccordionContent className='h-full bg-white px-4 py-2'>
                                <PurchaseDocumentTable items={item} />
                            </AccordionContent>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </div>
    );
}
