import { IPublicClientApplication } from '@azure/msal-browser';
import {
    FileText,
    LayoutGrid,
    LogOut,
    LucideIcon,
    PackageSearch,
    Settings,
    ShoppingBasket,
    Users,
} from 'lucide-react';

export interface MenuItem {
    href: string;
    label: string;
    active?: boolean;
    icon: LucideIcon;
    submenus?: SubmenuItem[];
    onClick?: () => void;
}

export interface SubmenuItem {
    href: string;
    label: string;
    active?: boolean;
}

export interface MenuGroup {
    groupLabel: string;
    menus: MenuItem[];
}

export function getMenuList(
    pathname: string,
    instance: IPublicClientApplication
): MenuGroup[] {
    console.log(pathname);
    return [
        {
            groupLabel: 'Main Menu',
            menus: [
                {
                    href: '/',
                    label: 'Dashboard',
                    active: pathname === '/',
                    icon: LayoutGrid,
                    submenus: [],
                },
                {
                    href: '',
                    label: 'Orders',
                    active:
                        pathname.includes('/orders') ||
                        pathname.includes('/purchases'),
                    icon: ShoppingBasket,
                    submenus: [
                        {
                            href: '/orders',
                            label: 'Received Orders',
                            active: pathname.includes('/orders'),
                        },
                        {
                            href: '/purchases',
                            label: 'Purchase Orders',
                            active: pathname.includes('/purchases'),
                        },
                    ],
                },
                {
                    href: '',
                    label: 'Products',
                    active:
                        pathname.includes('/products') ||
                        pathname.includes('/categories') ||
                        pathname.includes('/components'),
                    icon: PackageSearch,
                    submenus: [
                        {
                            href: '/products',
                            label: 'Items',
                            active: pathname.includes('/products'),
                        },
                        {
                            href: '/categories',
                            label: 'Categories',
                            active: pathname.includes('/categories'),
                        },
                        {
                            href: '/components',
                            label: 'Components',
                            active: pathname.includes('/components'),
                        },
                    ],
                },
                {
                    href: '/suppliers',
                    label: 'Suppliers',
                    active: pathname.includes('/suppliers'),
                    icon: Users,
                    submenus: [],
                },
                {
                    href: '',
                    label: 'Reports',
                    active: pathname.includes('/reports'),
                    icon: FileText,
                    submenus: [
                        { href: '/reports/substances', label: 'Substances' },
                        { href: '/reports/supplier', label: 'Suppliers' },
                        { href: '/reports/order', label: 'Orders' },
                        { href: '/reports/product', label: 'Products' },
                    ],
                },
            ],
        },
        {
            groupLabel: 'Settings',
            menus: [
                {
                    href: '/settings',
                    label: 'Workspace Settings',
                    active: pathname.includes('/settings'),
                    icon: Settings,
                    submenus: [],
                },
                {
                    href: '/',
                    label: 'Logout',
                    active: false,
                    icon: LogOut,
                    submenus: [],
                    onClick: () => {
                        instance.logoutRedirect();
                    },
                },
            ],
        },
    ];
}
