import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useRejectApprovalRequest } from 'src/app/_api_adb2c/product/product-approval/hooks/use-reject-approval-request';
import { ProductApprovalRequestRejectDto } from 'src/app/_api_adb2c/product/product-approval/request/product-approval-request-reject-dto';
import { BaseDialog } from 'src/app/components-v2/base-dialog';
import { TextareaInput } from 'src/app/components/Form/TextareaInput';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';

interface Props {
    open: boolean;
    approvalId: string;
    onClose: () => void;
}

const formSchema = z.object({
    reason: z.string(),
});

export function ProductRejection({ open, approvalId, onClose }: Props) {
    const { mutateAsync: reject, isLoading } = useRejectApprovalRequest();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const request: ProductApprovalRequestRejectDto = {
            reason: data.reason,
        };

        await reject({
            item: approvalId,
            body: request,
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    return (
        <BaseDialog
            open={open}
            onClose={reset}
            title='Reject Product'
            formName='reject-product-form'
            isLoading={isLoading}
        >
            <div className='grid grid-cols-1 gap-2'>
                <span className='text-xs'>
                    Are you sure you want to reject this product?
                    <br />
                </span>

                <Form {...form}>
                    <form
                        id='reject-product-form'
                        onSubmit={form.handleSubmit(onSubmit)}
                    >
                        <TextareaInput
                            name='reason'
                            placeholder='Enter Reason'
                        />
                    </form>
                </Form>
                {/* <Textarea className='' /> */}
                <span className='text-xs font-light'>
                    * This action cannot be undone. Please provide a supporting
                    reason for the action
                </span>
            </div>
        </BaseDialog>
    );
}
