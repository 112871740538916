import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactFlow, {
    Background,
    ControlButton,
    Controls,
    Edge,
    Node,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import { PurchaseOrderNature } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { getSupplyChainNodeTypeLabel } from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { Label } from 'src/components/ui/label';
import { Switch } from 'src/components/ui/switch';
import ApparelGroup from './apparel-grouping.json';
import { PurchaseCascadeApparelGroupNode } from './purchase-cascade-apparel-group-node';
import { PurchaseCascadeGroupNode } from './purchase-cascade-group-node';
import { PurchaseCascadeNode } from './purchase-cascade-node';
import { Trash } from 'lucide-react';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { useContextStore } from 'src/app/stores/context-store';
import { PurchaseClearCascade } from './purchase-clear-cascade';
import { usePurchaseCottonWeight } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-cotton-weight';
import { PurchaseCascadeDownloader } from './purchase-cascade-downloader';

interface Props {
    purchaseId: string;
}

enum CascadeNodeType {
    APPAREL_GROUP = 'APPAREL_GROUP',
    NODE_GROUP = 'NODE_GROUP',
    NODE = 'NODE',
}

const nodeTypes = {
    [CascadeNodeType.APPAREL_GROUP]: PurchaseCascadeApparelGroupNode,
    [CascadeNodeType.NODE_GROUP]: PurchaseCascadeGroupNode,
    [CascadeNodeType.NODE]: PurchaseCascadeNode,
};

const CustomOrderNatureLabel: Record<PurchaseOrderNature, string> = {
    [PurchaseOrderNature.COMPONENT]: 'Component',
    [PurchaseOrderNature.PROCESS]: 'Production - In-house',
    [PurchaseOrderNature.OUTSOURCE]: 'Production - Outsource',
    [PurchaseOrderNature.RAW_MATERIAL]: 'Raw Material',
};

export function PurchaseCascade({ purchaseId }: Props) {
    const context = useContextStore();
    const supplyChainLoaderType = context.workspace?.supplyChainLoaderType || 0;
    const isApparelMode = supplyChainLoaderType === 0;

    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [supplierMode, setSupplierMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const { data: purchase } = usePurchase(purchaseId, delegateId);
    const { data: purchaseCottonWeight } = usePurchaseCottonWeight(
        purchaseId,
        delegateId
    );

    const { data: traces } = usePurchaseTrace(purchaseId, delegateId);

    const getApparelGroup = useCallback((processes: string[]) => {
        if (!processes) return undefined;

        const formattedProcesses = processes.map((x) =>
            x.toLocaleLowerCase().trim()
        );

        const apparelGroup = ApparelGroup.find((x) =>
            x.processes.some((y) =>
                formattedProcesses.includes(y.toLocaleLowerCase())
            )
        );

        return apparelGroup;
    }, []);

    const getMatchingProcess = useCallback(
        (processes: string[]) => {
            if (!processes) return undefined;
            const apparelGroup = getApparelGroup(processes);
            const parsedProcesses = processes.map((x) => x.toLocaleLowerCase());

            for (const process of apparelGroup?.processes || []) {
                if (parsedProcesses.includes(process.toLocaleLowerCase())) {
                    return process;
                }
            }

            return undefined;
        },
        [getApparelGroup]
    );

    const createParentNode = useCallback(
        (purchase: PurchaseModel) => {
            const apparelGroup = getApparelGroup(purchase.processes);
            const matchingGroup = getMatchingProcess(purchase.processes);

            const nodes: Node[] = [];

            if (isApparelMode) {
                nodes.push({
                    id: `apparel-group-${purchase._id}`,
                    type: CascadeNodeType.APPAREL_GROUP,
                    position: { x: 20, y: 20 },
                    data: {
                        height: 210,
                        label: apparelGroup ? apparelGroup.name : 'Others',
                    },
                });
            }

            const groupProcess = matchingGroup
                ? matchingGroup
                : purchase.processes?.[0];

            const label = getSupplyChainNodeTypeLabel(
                groupProcess?.trim() || 'No Process'
            );

            nodes.push({
                id: `group-${purchase._id}`,
                type: CascadeNodeType.NODE_GROUP,
                position: { x: 8, y: 40 },
                parentNode: isApparelMode
                    ? `apparel-group-${purchase._id}`
                    : '',
                data: {
                    height: 160,
                    label: label,
                },
            });

            nodes.push({
                id: `node-${purchase._id}`,
                type: CascadeNodeType.NODE,
                position: { x: 8, y: 40 },
                parentNode: `group-${purchase._id}`,
                draggable: false,
                data: {
                    purchase: purchase,
                    supplierMode,
                    supplierName: purchase.supplier.seller.name,
                },
            });

            return nodes;
        },
        [getApparelGroup, getMatchingProcess, isApparelMode, supplierMode]
    );

    const createApparelTraceNodes = useCallback(
        (traces: PurchaseOrderTraceModel[], rootPurchase: PurchaseModel) => {
            const tier: {
                [key: number]: {
                    [key: string]: {
                        isApparelGroup: boolean;
                        traces: PurchaseOrderTraceModel[];
                        apparelGroup?: {
                            name: string;
                            processes: string[];
                        };
                    };
                };
            } = {};

            traces.forEach((trace) => {
                const depth = trace.depth;

                if (!tier[depth]) {
                    tier[depth] = {};
                }
            });

            traces.forEach((trace) => {
                const processes = trace.processes.map((x) =>
                    x.toLocaleLowerCase()
                );
                const currentTier = tier[trace.depth];

                const apparelGroup = getApparelGroup(processes);
                if (apparelGroup) {
                    if (!currentTier[apparelGroup.name.toLocaleLowerCase()]) {
                        currentTier[apparelGroup.name.toLocaleLowerCase()] = {
                            isApparelGroup: true,
                            traces: [],
                            apparelGroup: apparelGroup,
                        };
                    }

                    currentTier[
                        apparelGroup.name.toLocaleLowerCase()
                    ].traces.push(trace);
                } else {
                    if (!currentTier['other']) {
                        currentTier['other'] = {
                            isApparelGroup: false,
                            traces: [],
                            apparelGroup: {
                                name: 'Others',
                                processes: [],
                            },
                        };
                    }

                    currentTier['other'].traces.push(trace);
                }
            });

            const nodes: Node[] = [];

            Object.entries(tier).forEach(([tierKey, tierValue], index) => {
                let previousGroupHeight = 0;
                const currentTier = tierKey;

                Object.entries(tierValue).forEach(
                    ([processKey, processValue], processIndex) => {
                        const apparelGroup: Node = {
                            id: `apparel-group-${processKey}-${currentTier}`,
                            type: CascadeNodeType.APPAREL_GROUP,
                            position: {
                                x: 20 + 250 * (index + 1),
                                y: 20 + 60 * processIndex + previousGroupHeight,
                            },
                            data: {
                                height: 210 * processValue.traces.length,
                                label: processValue.apparelGroup?.name,
                            },
                        };

                        previousGroupHeight += apparelGroup.data.height;

                        nodes.push(apparelGroup);

                        processValue.traces.forEach((trace, index) => {
                            const process = getMatchingProcess(trace.processes);
                            let previousNodeHeight = 0;

                            const groupProcess = process
                                ? process
                                : trace.processes?.[0];

                            const label = getSupplyChainNodeTypeLabel(
                                groupProcess?.trim() || 'No Process'
                            );

                            const group: Node = {
                                id: `group-${trace._id}`,
                                type: CascadeNodeType.NODE_GROUP,
                                position: {
                                    x: 8,
                                    y: 40 + 200 * index + previousNodeHeight,
                                },
                                data: {
                                    height: 160,
                                    label: label,
                                },
                                draggable: false,
                                parentNode: `apparel-group-${processKey}-${currentTier}`,
                            };

                            previousNodeHeight += group.data.height;
                            let upperTierWeight: number;

                            if (trace.depth === 0) {
                                const cottonWeight =
                                    purchaseCottonWeight === -1
                                        ? 0
                                        : purchaseCottonWeight
                                        ? purchaseCottonWeight
                                        : 0;

                                upperTierWeight = cottonWeight;
                            } else {
                                const upperTrace = traces.find(
                                    (x) => x._id === trace.parent
                                );

                                const cottonWeight =
                                    upperTrace?.cottonWeight === -1
                                        ? 0
                                        : upperTrace?.cottonWeight
                                        ? upperTrace?.cottonWeight
                                        : 0;

                                upperTierWeight = cottonWeight;
                            }

                            const node: Node = {
                                id: `node-${trace._id}`,
                                type: CascadeNodeType.NODE,
                                position: { x: 8, y: 40 },
                                parentNode: `group-${trace._id}`,
                                draggable: false,
                                data: {
                                    supplierMode,
                                    purchase: trace,
                                    supplierName: trace.supplier.name,
                                    upperTierWeight,
                                },
                            };

                            nodes.push(group);
                            nodes.push(node);
                        });
                    }
                );
            });

            return nodes;
        },
        [
            getApparelGroup,
            getMatchingProcess,
            supplierMode,
            purchaseCottonWeight,
        ]
    );

    const createHardgoodsTraceNodes = useCallback(
        (traces: PurchaseOrderTraceModel[]) => {
            const nodes: Node[] = [];
            const parent = purchaseId;

            const item: {
                [key: string]: {
                    [key: string]: PurchaseOrderTraceModel[];
                };
            } = {};

            traces
                .sort((t1, t2) => {
                    const t1CreatedDate = new Date(t1.createdOn).getTime();
                    const t2CreatedDate = new Date(t2.createdOn).getTime();

                    return t1CreatedDate - t2CreatedDate;
                })
                .forEach((trace) => {
                    const tier = trace.depth?.toString();
                    const orderNature = trace.nature;

                    if (!tier) return;

                    if (!item[tier]) {
                        item[tier] = {};
                    }

                    if (!item[tier][orderNature]) {
                        item[tier][orderNature] = [];
                    }

                    item[tier][orderNature].push(trace);
                });

            Object.entries(item).forEach(([tier, processes], index) => {
                let previousNodeHeight = 0;

                Object.entries(processes).forEach(
                    ([process, purchases], processIndex) => {
                        const group = {
                            id: `group-${parent}-${index}-${process}`,
                            type: CascadeNodeType.NODE_GROUP,
                            position: {
                                x: 20 + 250 * (index + 1),
                                y: 20 + 60 * processIndex + previousNodeHeight,
                            },
                            data: {
                                tier: parseInt(tier),
                                height: 160 * purchases.length,
                                label:
                                    CustomOrderNatureLabel[
                                        process as PurchaseOrderNature
                                    ] || 'Unknown',
                            },
                        };

                        previousNodeHeight += group.data.height;

                        nodes.push(group);

                        purchases.forEach((purchase, index) => {
                            const node = {
                                id: `node-${purchase._id}`,
                                type: CascadeNodeType.NODE,
                                position: { x: 8, y: 40 + 160 * index },
                                parentNode: `group-${parent}-${tier}-${process}`,
                                draggable: false,
                                data: {
                                    supplierMode,
                                    purchase: purchase,
                                    supplierName: purchase.supplier.name,
                                },
                            };

                            nodes.push(node);
                        });
                    }
                );
            });

            return nodes;
        },
        [purchaseId, supplierMode]
    );

    const constructNodes = useCallback(() => {
        if (!purchase || !traces) return;

        const consolidatedNodes: Node[] = [];

        const parentNodes = createParentNode(purchase);
        consolidatedNodes.push(...parentNodes);

        let traceNodes: Node[] = [];
        if (isApparelMode) {
            traceNodes = createApparelTraceNodes(
                traces.sort((a, b) => a.depth - b.depth),
                purchase
            );
        } else {
            traceNodes = createHardgoodsTraceNodes(
                traces.sort((a, b) => a.depth - b.depth)
            );
        }

        consolidatedNodes.push(...traceNodes);

        setNodes(consolidatedNodes);
    }, [
        purchase,
        traces,
        isApparelMode,
        createParentNode,
        setNodes,
        createApparelTraceNodes,
        createHardgoodsTraceNodes,
    ]);

    const constructEdges = useCallback(() => {
        const edges: Edge[] = [];
        if (!purchase || !traces) return;

        const getColour = (type: PurchaseOrderNature) => {
            switch (type) {
                case PurchaseOrderNature.OUTSOURCE:
                    return '#FF9400';
                case PurchaseOrderNature.PROCESS:
                    return '#007C77';
                case PurchaseOrderNature.COMPONENT:
                    return '#000';
                default:
                    return '#007C77';
            }
        };

        traces.forEach((trace) => {
            console.log('trace is as so', trace.nature);
            edges.push({
                id: `${purchase._id} - ${trace._id}`,
                source: `node-${trace.parent}`,
                target: `node-${trace._id}`,
                zIndex: 1,
                style: {
                    stroke: getColour(trace.nature),
                },
            });
        });

        setEdges(edges);
    }, [traces, purchase, setEdges]);

    useEffect(() => {
        constructNodes();
    }, [constructNodes]);

    useEffect(() => {
        constructEdges();
    }, [constructEdges]);

    return (
        <>
            <div className='flex h-full flex-col gap-4'>
                <div className='flex items-center justify-between text-xs'>
                    <span className='font-bold'>Purchase Order Tracing</span>
                    <div className='flex items-center space-x-2'>
                        <Label className='text-xs'>Order</Label>
                        <Switch
                            checked={supplierMode}
                            onCheckedChange={setSupplierMode}
                        />
                        <Label className='text-xs'>Supplier</Label>
                    </div>
                </div>

                <div className='relative flex-1 rounded-lg border border-gray-200 bg-white'>
                    <ReactFlow
                        proOptions={{
                            hideAttribution: true,
                        }}
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        nodeTypes={nodeTypes}
                        defaultViewport={{
                            zoom: 0.5,
                            x: 0,
                            y: 0,
                        }}
                    >
                        <Background />
                        <Controls
                            position='bottom-right'
                            showInteractive={false}
                        >
                            <ControlButton onClick={() => setIsDelete(true)}>
                                <TooltipWrapper
                                    label='Delete Cascade'
                                    className='z-[100]'
                                >
                                    <Trash />
                                </TooltipWrapper>
                            </ControlButton>

                            <PurchaseCascadeDownloader />
                        </Controls>
                    </ReactFlow>

                    <div className='absolute bottom-[14px] left-[14px] flex h-24 w-48 flex-col justify-around rounded-lg border bg-white px-4 py-2'>
                        <span className='text-xs font-bold uppercase'>
                            Legend
                        </span>

                        <div className='flex flex-col text-xs'>
                            <div className='flex items-center gap-2'>
                                <div className='h-2 w-2 rounded-full bg-[#000]'></div>
                                <span>Component Orders</span>
                            </div>

                            <div className='flex items-center gap-2'>
                                <div className='h-2 w-2 rounded-full bg-[#007C77]'></div>
                                <span>Processing Orders</span>
                            </div>

                            <div className='flex items-center gap-2'>
                                <div className='h-2 w-2 rounded-full bg-[#FF9400]'></div>
                                <span>Outsourcing Orders</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isDelete && (
                <PurchaseClearCascade
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    purchase={purchase?._id || ''}
                    deleteParent={false}
                />
            )}
        </>
    );
}
